import styled from '@emotion/styled';
import { Icon } from '../icon';
import { Typography } from '../typography';
import { SemanticType, useSemantic } from '../../hooks/useSemantic';
import { ToastContainer, toast } from 'react-toastify';
import { theme } from '../../theme';
import { ToastOptions } from 'react-toastify/dist/types';

export type SnackBarProps = {
  text: string | JSX.Element;
  canClose?: boolean;
  type?: SemanticType;
  className?: string;
};

const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  max-width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;
  gap: 12px;
  min-width: 0;

  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 12px;
`;

const Text = styled(Typography.Body2)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
`;

export const SnackBarContainer = styled(ToastContainer)`
  padding-left: 1rem !important;
  top: 1rem !important;
  right: 1rem !important;
  width: auto !important;
  max-width: calc(min(100vw - 16px, 648px)) !important;

  .Toastify__toast-container {
    max-width: calc(min(100vw - 16px, 648px)) !important;
    width: auto !important;
    position: absolute !important;
    align-items: flex-end !important;
  }

  .Toastify__toast {
    min-height: 0;
    padding: 0;
    border-radius: 8px;
    background-color: transparent;
    width: fit-content;
    max-width: 100%;
    display: block !important;
    box-shadow: 0 2px 4px 0 #0000003d;
    margin-left: auto;
    margin-right: 0;
  }

  .Toastify__toast-body {
    display: block !important;
    padding: 0;
    height: fit-content;
    width: auto;
  }

  .Toastify__progress-bar {
    height: 2px;
  }
`;

export function SnackBar({
  text,
  canClose = true,
  type = 'info',
  ...rest
}: SnackBarProps): JSX.Element | null {
  const { getIcon, getBackground, getColor } = useSemantic();

  return (
    <Container backgroundColor={getBackground(type)} {...rest}>
      <LeftWrapper>
        <Icon icon={getIcon(type)} bgColor={getColor(type)} size="M" />
        <Text>{text}</Text>
      </LeftWrapper>
      {canClose && (
        <CloseButton data-testid="close-button">
          <Icon icon="CloseIcon" size="M" />
        </CloseButton>
      )}
    </Container>
  );
}

const progressBarColor = {
  info: theme.tokens.color.alias.cm.icon['icon-info'].value,
  success: theme.tokens.color.alias.cm.icon['icon-success'].value,
  error: theme.tokens.color.alias.cm.icon['icon-error'].value,
  warning: theme.tokens.color.alias.cm.icon['icon-warning'].value,
};

export const snack = (
  text?: string,
  toastOptions?: ToastOptions,
  canClose?: boolean
) => {
  if (!text) return;
  return toast(<SnackBar text={text} canClose={canClose} />, {
    progressStyle: { background: progressBarColor['info'] },
    closeOnClick: canClose,
    ...toastOptions,
  });
};

snack.success = (
  text?: string,
  toastOptions?: ToastOptions,
  canClose?: boolean
) => {
  if (!text) return;
  return toast(<SnackBar text={text} canClose={canClose} type="success" />, {
    progressStyle: { background: progressBarColor['success'] },
    closeOnClick: canClose,
    ...toastOptions,
  });
};

snack.error = (
  text?: string,
  toastOptions?: ToastOptions,
  canClose?: boolean
) => {
  if (!text) return;
  return toast(<SnackBar text={text} canClose={canClose} type="error" />, {
    progressStyle: { background: progressBarColor['error'] },
    closeOnClick: canClose,
    ...toastOptions,
  });
};

snack.warning = (
  text?: string,
  toastOptions?: ToastOptions,
  canClose?: boolean
) => {
  if (!text) return;
  return toast(<SnackBar text={text} canClose={canClose} type="warning" />, {
    progressStyle: { background: progressBarColor['warning'] },
    closeOnClick: canClose,
    ...toastOptions,
  });
};
