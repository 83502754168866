import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import { TableCell } from '@mui/material';

export const AdminLabel = styled(Typography.Subtitle2)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: default;
  width: fit-content;
  position: relative;
`;

export const AdminSublabel = styled(Typography.Body3)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  cursor: default;
  position: relative;
  width: fit-content;
`;

export const AdminHeaderTableCell = styled(TableCell)<{
  isSticky?: boolean;
  left?: number;
  separator?: boolean;
}>`
  height: 100%; // Height arbitrario para que haga el flex
  left: ${({ isSticky, left }) => (isSticky ? `${left}px` : '')};
  top: -24px !important;
  z-index: ${({ isSticky }) => (isSticky ? '15 !important' : '6 !important')};

  ${({ separator, theme }) => {
    if (separator) {
      return css`
        padding: 0 !important;
        border-bottom: none;
      `;
    }
    return css`
      padding: 12px 8px 8px 8px !important;
      border-bottom: 1px solid
        ${theme.tokens.color.specific.reports.tables['divider-primary'].value};
    `;
  }}
`;

export const AdminHeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;
`;

export const AdminHeaderSeparator = styled.div<{ isSticky?: boolean }>`
  width: 1px;
  height: 60px;
  margin: 0 -1px;
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.reports.tables['divider-secondary'].value};
  position: absolute;
  bottom: 0;
`;

export const AdminLabelInfoIcon = styled.div`
  position: absolute;
  top: 0;
  right: 1px;
  transform: translate(75%, -50%);
`;

export const AdminLabelText = styled.span<{ color?: string }>`
  display: block;
  position: relative;
  width: 100%;
`;

export const AdminSublabelInfoIcon = styled.div`
  position: absolute;
  top: 0;
  right: 1px;
  transform: translate(100%, -50%);
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
