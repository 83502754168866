import styled from '@emotion/styled';
import { Tooltip } from '@innovamat/glimmer-components';
import { MinorText, ReportsTypography } from '../common.styled';

type Props = {
  completed: number;
  total: number;
  rowIsNull: boolean;
  tooltip?: JSX.Element | undefined;
  dataTestId?: string;
  text?: string;
};

const PlaceholderSessions = styled(ReportsTypography)`
  display: flex;
  align-items: flex-end;
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
`;

const DoubleDash = styled(ReportsTypography)`
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
`;

function ProgressCounter({
  completed,
  total,
  rowIsNull,
  tooltip,
  dataTestId,
  text,
}: Props): JSX.Element {
  const getTotalText = () => {
    if (!text) return total;
    return `${total} ${text}`;
  };

  if (rowIsNull)
    return (
      <PlaceholderSessions>
        <DoubleDash>--</DoubleDash>/<MinorText>{getTotalText()}</MinorText>
      </PlaceholderSessions>
    );

  return (
    <Tooltip content={tooltip}>
      <ReportsTypography data-testid={dataTestId}>
        {completed}/<MinorText>{getTotalText()}</MinorText>
      </ReportsTypography>
    </Tooltip>
  );
}

export { ProgressCounter };
