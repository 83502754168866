import { InputRangeSlider } from '@innovamat/glimmer-components';
import React, { useEffect, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import DefaultAvatar from './default-avatar.svg';
import { Modal } from '../modal';
import styled from '@emotion/styled';
import { Typography } from '../typography';
import { Icon } from '../icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  canvas {
    border-radius: 16px;
  }
`;

const InputContainer = styled.div`
  cursor: pointer;
  margin-top: 8px;

  input {
    display: none;
  }
`;

const RangeContainer = styled.div`
  margin-top: 16px;
  width: 228px;
`;

const DottedCircle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background-image: ${({ theme }) => {
    const strokeColor = encodeURIComponent(
      theme.tokens.color.alias.cm.border['border-subtle'].value
    );
    return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='${strokeColor}' stroke-width='2' stroke-dasharray='5.2%25' stroke-dashoffset='82' stroke-linecap='square'/%3e%3c/svg%3e")`;
  }};
  border-radius: 100%;
  cursor: pointer;
  padding: 8px;

  :hover {
    background-color: ${({ theme }) =>
      theme.tokens.color.alias.cm.surface['surface-secondary'].value};
    transition: background-color 300ms;
  }

  input {
    display: none;
  }
`;

const SubtleText = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type AvatarEditorProps = {
  open: boolean;
  img?: string | File | null;
  onClose: () => void;
  onUploadImg: (img: File | undefined) => void;
  onSaveImg: (img: string) => void;
  t: (key: string) => string;
  isClassroom?: boolean;
  isLoading?: boolean;
};

export const AvatarEditor = ({
  open,
  img,
  onClose,
  onUploadImg,
  onSaveImg,
  t,
  isClassroom,
  isLoading,
}: AvatarEditorProps) => {
  const editor = React.createRef<ReactAvatarEditor>();
  const [scale, setScale] = useState(1.0);

  useEffect(() => {
    setScale(1.0);
  }, [open]);

  const handleClickInputFile = () => {
    document.getElementById('inputfile')?.click();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    const result = editor.current?.getImageScaledToCanvas().toDataURL();
    if (result) {
      onSaveImg(result);
    }
  };

  return (
    <Modal
      isOpen={open}
      title={t('common.addImage') as string}
      buttons={[
        {
          children: t('Settings.Guardar'),
          variant: 'accent',
          onClick: handleSave,
          disabled: !img,
          loading: isLoading,
        },
        {
          children: t('Settings.Cancel'),
          variant: 'tertiary',
          onClick: handleClose,
        },
      ]}
      modalWidth={464}
      onClose={handleClose}
    >
      <Container>
        {!img ? (
          <DottedCircle role="presentation" onClick={handleClickInputFile}>
            <input
              id="inputfile"
              type="file"
              onChange={(e) =>
                e?.target?.files && onUploadImg(e.target.files[0])
              }
            />
            <Icon icon="AddPictureIcon" size="XL" />
            <SubtleText>{t('avatar-editor.add-image')}</SubtleText>
          </DottedCircle>
        ) : (
          <>
            <ReactAvatarEditor
              ref={editor}
              image={img || DefaultAvatar}
              width={128}
              height={128}
              border={50}
              color={[196, 196, 196, 0.6]}
              scale={scale}
              rotate={0}
              borderRadius={isClassroom ? 16 : 100}
              crossOrigin="anonymous"
            />
            <RangeContainer>
              <InputRangeSlider
                disabled={!img}
                max={3}
                min={0.5}
                onChange={(value) => setScale(value)}
                step={0.1}
                value={scale}
              />
            </RangeContainer>
            <InputContainer role="presentation" onClick={handleClickInputFile}>
              <input
                id="inputfile"
                type="file"
                onChange={(e) =>
                  e?.target?.files && onUploadImg(e.target.files[0])
                }
              />
              <Typography.Link1>
                {t('avatar-editor.add-new-image')}
              </Typography.Link1>
            </InputContainer>
          </>
        )}
      </Container>
    </Modal>
  );
};
