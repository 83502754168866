import defaultAppletThumbnail from './defaultApplet.png';
import { Icon, InnerHtml } from '@innovamat/innova-components';
import { useEffect, useState } from 'react';
import Portal from '../Portal';
import { normalizeImgSource } from '../../../../utils';
import {
  ImageComponent,
  theme,
  Typography,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

type Props = {
  gif: string | undefined;
  label: string;
  handleShowAppletPreview: (sceneName: string, interacting?: boolean) => void;
  containerId: string;
  stickyTable?: boolean;
  appletId?: string;
  handleSendEvent: (event: string, parameters?: {}) => void;
  handleNavigateToResource: (appletId: string) => void;
};

type Coords = {
  top: string | null;
  left: string | null;
};

const AppletPreviewWidth = 328;
const AppletPreviewHeight = 214;
const AppletPreviewHeightSticky = -30;

const AppletPreview = (props: Props): JSX.Element => {
  const {
    gif,
    label,
    handleShowAppletPreview,
    containerId,
    stickyTable,
    appletId,
    handleSendEvent,
    handleNavigateToResource,
  } = props;
  const [coords, setCoords] = useState<Coords>({
    top: null,
    left: null,
  });

  const container = document
    .getElementById(containerId)
    ?.getBoundingClientRect();
  const tableContainer = document
    .getElementById('reports-table-container')
    ?.getBoundingClientRect();

  useEffect(() => {
    if (!container || !tableContainer) return;
    const left = `${
      container.left - tableContainer.left - AppletPreviewWidth / 2
    }px`;
    if (stickyTable) {
      const top = `${
        container.top - tableContainer.top - AppletPreviewHeightSticky - 8
      }px`;
      setCoords({ top, left });
    } else {
      const top = `${
        container.top - tableContainer.top - AppletPreviewHeight - 8
      }px`;
      setCoords({ top, left });
    }
  }, [container?.left, container?.top]);

  const handleAppletClick = (appletId: string): void => {
    handleSendEvent('open_applet_tooltip', { applet_id: appletId });
    handleNavigateToResource(appletId);
  };

  const ImageSrc = normalizeImgSource(defaultAppletThumbnail);

  return (
    <Portal top={coords.top} left={coords.left}>
      {label && coords.left && coords.top && (
        <Container
          width={AppletPreviewWidth}
          height={AppletPreviewHeight}
          onMouseEnter={() => handleShowAppletPreview(`${containerId}`, true)}
          onMouseLeave={() => handleShowAppletPreview('', false)}
          onClick={() => handleAppletClick(appletId || '')}
        >
          <Gif alt="applet-gif" src={gif || ImageSrc} />
          <TitleContainer>
            <InnerHtml text={label} />
            {label && <Icon icon="ArrowRight" />}
          </TitleContainer>
        </Container>
      )}
    </Portal>
  );
};

export default AppletPreview;

const Container = styled.div<{ width: number; height: number }>`
  background-color: ${theme.tokens.color.global.white.value};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  height: auto;
  aspect-ratio: 16/9;
  cursor: pointer;
`;

const Gif = styled(ImageComponent)`
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled(Typography.Body3)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  background-color: ${theme.tokens.color.global.white.value};
`;
