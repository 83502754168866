import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { snack } from '@innovamat/glimmer-components';

import { useDeleteStudent } from '../../../hooks/use-delete-student';

import type { SchoolStudent } from '../types/student';

import { ConfirmDeleteStudentModal } from '../../../components/confirm-delete-student.modal';
import { MoveStudentToClassroomDrawer } from '../../../components/move-student-to-classroom-drawer/move-student-to-classroom-drawer';
import { RecoverStudentModal } from '../components/recover-student-modal';

type SchoolStudentsValue = {
  isDeletedStudentsPage: boolean;
  isOpenRecoverModal: boolean;
  search: string;
  student: SchoolStudent | undefined;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeClassroom: (student: SchoolStudent) => void;
  onDeleteStudent: (student: SchoolStudent) => void;
  onRecoverStudent: (student: SchoolStudent) => void;
  setIsOpenRecoverModal: (value: boolean) => void;
  setStudent: (value: SchoolStudent | undefined) => void;
};

const SchoolStudentsContext = createContext<SchoolStudentsValue | undefined>(
  undefined
);

type Props = {
  children: JSX.Element;
  isDeletedStudentsPage: boolean;
};

function SchoolStudentsProvider({
  isDeletedStudentsPage,
  children,
}: Props): JSX.Element {
  const [search, setSearch] = useState('');
  const [student, setStudent] = useState<SchoolStudent | undefined>();
  const [isOpenRecoverModal, setIsOpenRecoverModal] = useState(false);
  const [isOpenChangeClassroomDrawer, setIsOpenChangeClassroomDrawer] =
    useState(false);
  const [isOpenConfirmDeleteStudentModal, setIsOpenConfirmDeleteStudentModal] =
    useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { onDeleteStudent, isDeletingLoading } = useDeleteStudent({
    onError: () => {},
    onSuccess: () => {
      snack.success(t('students.toast.success.deleteStudent'));
      queryClient.refetchQueries({ queryKey: ['Organization'] });
      queryClient.refetchQueries({ queryKey: ['SchoolStudents'] });
      handleCloseConfirmDeleteStudentModal();
    },
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const handleRecoverStudent = (student: SchoolStudent): void => {
    setIsOpenRecoverModal(true);
    setStudent(student);
  };

  const handleChangeClassroom = (student: SchoolStudent): void => {
    setIsOpenChangeClassroomDrawer(true);
    setStudent(student);
  };

  const handleDeleteStudent = (student: SchoolStudent): void => {
    setIsOpenConfirmDeleteStudentModal(true);
    setStudent(student);
  };

  const handleCloseRecoveModal = (): void => {
    setStudent(undefined);
    setIsOpenRecoverModal(false);
  };

  const handleCloseConfirmDeleteStudentModal = (): void => {
    setStudent(undefined);
    setIsOpenConfirmDeleteStudentModal(false);
  };

  const handleCloseChangeClassroomDrawer = (): void => {
    setStudent(undefined);
    setIsOpenChangeClassroomDrawer(false);
  };

  const value: SchoolStudentsValue = {
    isDeletedStudentsPage,
    search,
    handleSearch,
    isOpenRecoverModal,
    setIsOpenRecoverModal,
    student,
    setStudent,
    onRecoverStudent: handleRecoverStudent,
    onChangeClassroom: handleChangeClassroom,
    onDeleteStudent: handleDeleteStudent,
  };

  return (
    <SchoolStudentsContext.Provider value={value}>
      {children}
      <RecoverStudentModal
        isOpen={isOpenRecoverModal}
        onClose={handleCloseRecoveModal}
        student={student}
      />
      <MoveStudentToClassroomDrawer
        onClose={handleCloseChangeClassroomDrawer}
        showDrawer={isOpenChangeClassroomDrawer}
        student={student}
        onSuccess={() => {
          queryClient.refetchQueries({ queryKey: ['SchoolStudents'] });
        }}
      />
      <ConfirmDeleteStudentModal
        onConfirm={() => student && onDeleteStudent(student)}
        onDismiss={handleCloseConfirmDeleteStudentModal}
        isOpen={isOpenConfirmDeleteStudentModal}
        isLoading={isDeletingLoading}
        student={student}
      />
    </SchoolStudentsContext.Provider>
  );
}

function useSchoolStudents(): SchoolStudentsValue {
  const context = useContext(SchoolStudentsContext);
  if (context === undefined) {
    throw new Error(
      'useSchoolStudents must be used within a SchoolStudentsProvider'
    );
  }
  return context;
}

export { SchoolStudentsProvider, useSchoolStudents };
