import type { TokenData } from '@innovamat/radiance-utils';
import { authService } from '@innovamat/radiance-utils';
import type { RequestDocument } from 'graphql-request';
import { GraphQLClient } from 'graphql-request';

interface Variables {
  [key: string]: any;
}

export const REQUEST_ERRORS = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  OTHER: 'OTHER',
} as const;

export type RequestError = (typeof REQUEST_ERRORS)[keyof typeof REQUEST_ERRORS];

class ExpiredTokenError extends Error {
  response: any;

  constructor(message: string) {
    super(message);
    this.name = 'ExpiredTokenError';
    this.response = {
      errors: [
        {
          extensions: {
            response: {
              body: {
                message: 'Expired JWT',
                type: 'auth.expired_jwt',
                detail: 'Signature has expired.',
              },
            },
          },
        },
      ],
    };
  }
}

export class CustomGraphQLClient {
  private graphqlClient: GraphQLClient;
  private refreshTokenPromise: Promise<void> | null = null;

  private getAuthToken;
  private getUserAcceptLanguage;
  private onRefreshToken;
  private onRequestError;

  constructor(
    url: string,
    getAuthToken: () => string | undefined,
    getUserAcceptLanguage: () => string | undefined,
    onRefreshToken: () => Promise<TokenData>,
    onRequestError: (error: RequestError) => void
  ) {
    this.getAuthToken = getAuthToken;
    this.getUserAcceptLanguage = getUserAcceptLanguage;
    this.onRefreshToken = onRefreshToken;
    this.onRequestError = onRequestError;

    const headers: Record<string, string> = {};

    const authToken = getAuthToken();

    if (authToken) {
      headers.authorization = authToken;
    }

    this.graphqlClient = new GraphQLClient(url, {
      headers,
    });
  }

  isExpired(error: any): boolean {
    return (
      error.extensions?.response?.['body']?.message === 'Expired JWT' ||
      error.extensions?.response?.['body']?.type === 'auth.expired_jwt' ||
      error.extensions?.response?.['body']?.detail === 'Signature has expired.'
    );
  }

  private async refreshTokenIfNeeded(): Promise<void> {
    if (!this.refreshTokenPromise) {
      this.refreshTokenPromise = this.onRefreshToken()
        .then(() => {
          const language = this.getUserAcceptLanguage();
          const authToken = this.getAuthToken();

          if (language) {
            this.graphqlClient.setHeader('accept-language', language);
          }

          if (authToken) {
            this.graphqlClient.setHeader('authorization', authToken);
          }
        })
        .finally(() => {
          this.refreshTokenPromise = null;
        });
    }
    return this.refreshTokenPromise;
  }

  async request<T = any, V = Variables>(
    query: RequestDocument,
    variables?: V,
    headers?: Record<string, string>
  ): Promise<T> {
    try {
      const language = this.getUserAcceptLanguage();
      const authToken = this.getAuthToken();

      if (language) {
        this.graphqlClient.setHeader('accept-language', language);
      }

      if (authToken) {
        this.graphqlClient.setHeader('authorization', authToken);
      }

      if (headers) {
        Object.keys(headers).forEach((key) => {
          this.graphqlClient.setHeader(key, headers[key]);
        });
      }

      if (authService.isTokenExpiringSoon(authToken?.replace('Bearer ', ''))) {
        throw new ExpiredTokenError('Token is expiring soon');
      }

      return await this.graphqlClient.request<T>(query, variables as Variables);
    } catch (error: any) {
      if ('response' in error && error.response.errors.some(this.isExpired)) {
        await this.refreshTokenIfNeeded();
        return this.graphqlClient.request<T>(query, variables as Variables);
      } else {
        if (
          error.response?.errors?.some(
            (err: any) =>
              !this.isExpired(err) &&
              err?.extensions?.code === 'UNAUTHENTICATED'
          )
        ) {
          this.onRequestError(REQUEST_ERRORS.UNAUTHENTICATED);
        } else {
          this.onRequestError(REQUEST_ERRORS.OTHER);
        }
        throw error;
      }
    }
  }
}
