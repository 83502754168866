import { useEffect, useState } from 'react';
import type { ApexOptions } from 'apexcharts';
import { useTranslation } from 'react-i18next';
import { getScoreColor } from '../../utils/getScoreColor';
import { getFixedLocalizedScore } from '../../utils/common';
import { dates } from '@innovamat/radiance-utils';
import { heights } from '../../utils/getScoreHeights';
import { ApexChartWrapper, getTypography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

type Props = {
  scores: number[];
  scoresHeights: number[];
  startDate: string[];
  endDate: string[];
  width: number;
  completed?: number[];
  dotsRight?: boolean;
  dotsLeft?: boolean;
  handleClickDataPoint: (dataPoint: { from: string; to: string }) => void;
  dataTestId?: string;
  isUs?: boolean;
};

const LineChart = ({
  scores,
  scoresHeights,
  startDate,
  endDate,
  completed,
  width,
  dotsRight,
  dotsLeft,
  handleClickDataPoint,
  dataTestId,
  isUs,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const getMarkersColor = (): ApexDiscretePoint[] => {
    const discrete = [];
    for (let i = 0; i < scores.length; i++) {
      discrete.push({
        seriesIndex: 0,
        dataPointIndex: i,
        fillColor: getScoreColor(scores[i], { useNoDataToken: true }),
        strokeColor: '#fff',
        size: 7,
      });
    }
    return discrete;
  };

  //TODO: Uncomment this calculation if the product team decides to display the percentage completed.
  // const getPercentage = (total: number, completed: number) =>
  //   Math.ceil((completed * 100) / total);

  const options: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (_, __, config) =>
          handleClickDataPoint({
            from: startDate[config.dataPointIndex],
            to: endDate[config.dataPointIndex],
          }),
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    yaxis: {
      show: false,
      tooltip: {
        enabled: false,
      },
      tickAmount: 3,
      min: 0,
      max: 48,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        width: '0px', // Esto oculta las líneas del eje X
      },
      tooltip: {
        enabled: false,
      },
    },
    noData: {
      text: t('common.loadingContent'),
    },
    markers: {
      hover: {
        size: 9,
      },
      discrete: getMarkersColor(),
    },
    stroke: {
      show: true,
      curve: 'smooth',
      colors: ['#757575'],
      width: 2,
    },
    tooltip: {
      shared: false,
      intersect: true,
      custom: function ({
        dataPointIndex,
      }: {
        dataPointIndex: number;
      }): string {
        //TODO: Uncomment this code if the product team decides to display the percentage completed.
        // const complet = getPercentage(100, completed[dataPointIndex]);
        const score = scores[dataPointIndex];
        const noConnected = t('reports.noConnected.cell');
        if (score === null) {
          return (
            '<div class="apexcharts-tooltip-box apexcharts-tooltip-boxPlot">' +
            '<span class="bold">' +
            noConnected +
            '</span>' +
            '</div>'
          );
        }
        return (
          '<div class="apexcharts-tooltip-box apexcharts-tooltip-boxPlot">' +
          '<span>' +
          dates.getFormattedDayMonthDate(
            startDate[dataPointIndex],
            isUs ? 'LL/dd' : 'dd/LL'
          ) +
          '<span>' +
          '-' +
          '</span>' +
          dates.getFormattedDayMonthDate(
            endDate[dataPointIndex],
            isUs ? 'LL/dd' : 'dd/LL'
          ) +
          ':' +
          '</span>' +
          '<span class="bold">' +
          getFixedLocalizedScore(score) +
          '</span>' +
          //TODO: Uncomment these lines if the product team decides to display the percentage completed.
          // '<span>' +
          // `(${t('reports.percentage.completedApplets', {
          //   complet: complet,
          // })})` +
          // '</span>' +
          '</div>'
        );
      },
    },
  };

  const series = [
    {
      name: 'score',
      data: scoresHeights,
    },
  ];

  const firstElement = scoresHeights[0];
  const lastElement = scoresHeights[scoresHeights.length - 1];
  const [position, setPosition] = useState('');

  function positionDashLine(element: number): void {
    if (element === heights.LOW_HEIGHT) {
      setPosition('22px');
    } else if (element === heights.MEDIUM_HEIGHT) {
      setPosition('8px');
    } else if (element === heights.MEDIUM_HIGH_HEIGHT) {
      setPosition('-8px');
    } else if (element === heights.HIGH_HEIGHT) {
      setPosition('-22px');
    }
  }

  useEffect(() => {
    if (dotsLeft) {
      positionDashLine(firstElement);
    }
    if (dotsRight) {
      positionDashLine(lastElement);
    }
  }, [dotsLeft, dotsRight, firstElement, lastElement]);

  return (
    <Container>
      {dotsLeft && <DashLine position={position} />}
      <ApexChartWrapper
        options={options}
        series={series}
        type="line"
        width={width}
        height="64"
        id="chart"
        data-testid={dataTestId}
      />
      {dotsRight && <DashLine position={position} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  min-height: 36px !important;
  max-height: 36px !important;

  .apexcharts-series-markers-wrap {
    transition: none !important;
  }

  .apexcharts-xaxis-tick {
    stroke: none !important;
  }

  circle {
    :hover {
      filter: ${({ theme }) =>
        `drop-shadow(0px 2px 2px ${theme.tokens.elevation['elevation 2'].value.color})`};
    }
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: 0px 0px 0px 0px !important;
  }

  .apexcharts-tooltip {
    top: 18px !important;
  }

  .apexcharts-marker {
    transition: none !important;
    cursor: pointer !important;
  }

  .apexcharts-tooltip-boxPlot {
    display: flex;
    flex-direction: row;
  }

  .apexcharts-tooltip-box {
    background-color: ${({ theme }) =>
      ` ${theme.tokens.color.alias.cm.bg['bg-neutral-inverted'].value}`};
    color: ${({ theme }) =>
      ` ${theme.tokens.color.alias.cm.text['text-inverted'].value}`};
    border: ${({ theme }) =>
      `1px solid ${theme.tokens.color.alias.cm.bg['bg-neutral-inverted'].value}`};
    border-radius: 4px;
    ${({ theme }) => getTypography(theme, 'Body 3')}
    line-height: 16px;
    font-size: 10px;
    padding: 2px 6px;
    span {
      margin: 0 2px;
    }
    .bold {
      font-weight: bold;
    }
  }
  .apexcharts-xaxis-tick {
    stroke: ${({ theme }) => ` ${theme.tokens.color.global.white.value}`};
  }
`;

const DashLine = styled.span<{ position: string }>`
  height: 2px;
  width: 10px;
  margin-right: -6px;
  margin-left: -2px;
  margin-top: ${({ position }) => position};
  background: repeating-linear-gradient(90deg, #757575 0 3px, #0000 0 5px);
`;

export { LineChart };
