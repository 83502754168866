import { Tooltip } from '@innovamat/glimmer-components';
import useElementVisibility from '../../hooks/use-element-visibility';
import { getAppletReadableName } from '../../utils/common';
import AppletPreview from '../AppletPreview';
import { TFunction } from 'i18next';
import {
  ContentApplet,
  Maybe,
  BlockContent,
  TextTranslation,
} from '@innovamat/glow-api-client';
import { BubblesSubLabelsWrapper, Text } from '../common.styled';

// TODO: Refctor this to avoid (applet as ContentApplet)

type BubbleSubLabelWrapperProps = {
  applet: Maybe<ContentApplet | BlockContent>;
  handleShowAppletPreview: (sceneName: string, interacting?: boolean) => void;
  visibleAppletPreview: string;
  id?: string;
  t: TFunction<'translation', undefined, 'translation'>;
  isContents?: boolean;
  stickyTable?: boolean;
  handleSendEvent: (event: string, parameters?: {}) => void;
  handleNavigateToResource: (appletId: string) => void;
};

function BubbleSubLabel({
  applet,
  handleShowAppletPreview,
  visibleAppletPreview,
  id,
  t,
  isContents,
  stickyTable,
  handleSendEvent,
  handleNavigateToResource,
}: BubbleSubLabelWrapperProps): JSX.Element {
  const { isElementHidden: isHeaderHidden, elementRef } =
    useElementVisibility();
  const containerId = `report-sublabel-${
    (applet as ContentApplet).data?.name
  }-${(applet as ContentApplet).sceneName}+${id}`;
  const thumbnail = (applet as ContentApplet).data?.thumbnail as string;
  const labelAppletPreview = (applet as ContentApplet).data?.name || '';
  const appletId = (applet as ContentApplet).data?.id || '';
  const { contentTitle, contentTooltip } = applet as BlockContent;

  const getContentLabel = (): string => {
    const { requiresTranslation, text } = contentTitle as TextTranslation;
    return requiresTranslation ? t(`${text}`) : `${text}`;
  };

  const getTitle = () => {
    if (isContents) {
      return getContentLabel();
    }
    return getAppletReadableName(
      (applet as ContentApplet).data?.name,
      (applet as ContentApplet).sceneName,
      (applet as ContentApplet).pack
    );
  };

  const isALargeText = (text: string): boolean => {
    return text.split(' ').length > 6;
  };

  const getTooltip = () => {
    const {
      requiresTranslation: tooltipRequiresTranslation,
      text: tooltipText,
    } = (contentTooltip as TextTranslation) ?? {};
    if (tooltipRequiresTranslation) {
      return t(`${tooltipText}`);
    }
    if (isALargeText(t(`${contentTitle!.text}`))) {
      return getContentLabel();
    }
    const appletKey = (applet as ContentApplet)?.contentTextCode;
    return appletKey ? t(appletKey) : '';
  };

  if (isContents) {
    return (
      <BubblesSubLabelsWrapper>
        <Tooltip content={getTooltip()} popperOptions={{ strategy: 'fixed' }}>
          <Text>{getTitle()}</Text>
        </Tooltip>
      </BubblesSubLabelsWrapper>
    );
  }
  const isPreviewVisble = containerId === visibleAppletPreview;
  return (
    <BubblesSubLabelsWrapper
      id={isHeaderHidden ? '' : containerId}
      key={(applet as ContentApplet).sceneName}
      onMouseEnter={() => handleShowAppletPreview(containerId, true)}
      onMouseLeave={() => handleShowAppletPreview('')}
      ref={elementRef}
    >
      <>
        {isPreviewVisble && (
          <AppletPreview
            label={labelAppletPreview}
            handleShowAppletPreview={handleShowAppletPreview}
            gif={thumbnail}
            containerId={containerId}
            stickyTable={stickyTable}
            appletId={appletId}
            handleSendEvent={handleSendEvent}
            handleNavigateToResource={handleNavigateToResource}
          />
        )}
        <div>{getTitle()}</div>
      </>
    </BubblesSubLabelsWrapper>
  );
}

export default BubbleSubLabel;
