import styled from '@emotion/styled';
import { Bar, useGlimmerTheme } from '@innovamat/glimmer-components';
import { t } from 'i18next';
import { ReportsTypography } from '../../../../components/common.styled';
import { getFixedLocalizedScoreWithZero } from '../../../../utils/common';
import PlaceholderCell from '../placeholder-cell';

type Props = {
  score: number;
  studentsPerStrip: number[];
  rowIsNull: boolean;
  courseOrder: number;
  classroomName: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Wrapper = styled.div`
  width: 30px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AverageScoreBar = ({
  score,
  studentsPerStrip,
  rowIsNull,
  courseOrder,
  classroomName,
}: Props): JSX.Element => {
  const theme = useGlimmerTheme();

  const baseTokenReport = theme.tokens.color.specific.reports.semantic;

  const barColors = [
    baseTokenReport.low.value,
    baseTokenReport.medium.value,
    baseTokenReport.high.value,
    baseTokenReport['super-high'].value,
  ];

  const placeholderColor =
    theme.tokens.color.specific.reports['progress-bar'].empty.value;

  const barPlaceholderColor = [
    placeholderColor,
    placeholderColor,
    placeholderColor,
    placeholderColor,
  ];

  const studentsPerStripTooltips = studentsPerStrip.map(
    (students) =>
      `${students.toString()} ${t(
        'reports.admin.averageScoreBar.tooltip',
        'Al.'
      )}`
  );

  const isNull = score === null;

  const renderScore = (): JSX.Element => {
    if (rowIsNull || isNull)
      return (
        <Wrapper>
          <FlexContainer>
            <PlaceholderCell
              data-testid={`${courseOrder}_${classroomName}_DIGITALPRACTICE`}
            />
          </FlexContainer>
        </Wrapper>
      );
    return (
      <Wrapper>
        <ReportsTypography
          data-testid={`${courseOrder}_${classroomName}_DIGITALPRACTICE`}
        >
          {score === 10 ? score : getFixedLocalizedScoreWithZero(score)}
        </ReportsTypography>
      </Wrapper>
    );
  };

  const renderBar = (): JSX.Element => {
    if (rowIsNull || isNull)
      return (
        <Bar
          type="percentage"
          colors={barPlaceholderColor}
          values={[25, 25, 25, 25]}
          width={120}
          height={8}
        />
      );
    return (
      <Bar
        type="values"
        colors={barColors}
        values={studentsPerStrip}
        tooltips={studentsPerStripTooltips}
        width={120}
        height={8}
      />
    );
  };

  return (
    <Container>
      {renderScore()}
      {renderBar()}
    </Container>
  );
};
export default AverageScoreBar;
