import { stages } from '@innovamat/radiance-utils';
import { useEffect, useState } from 'react';
import { Typography } from '../typography';
import { AvatarEditor } from '../avatar-editor';
import styled from '@emotion/styled';
import { Avatar } from '../avatar';
import { ClassroomAvatar } from '../classroom-avatar';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    gap: 8px;
    flex-wrap: wrap;
  }
`;

const Description = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type Props = {
  images: { id: number | string; image: string }[];
  selectedIcon: number | null;
  onSelectIcon: (id: number | string) => void;
  noImageText?: string;
  onSelectAvatar: (avatar: string | null) => void;
  selectedAvatar: string | null;
  selectedCourseOrder?: number | null;
  label?: string;
  t: (key: string) => string;
  onClick?: () => void;
};

export function ImageSelector({
  images,
  selectedIcon,
  noImageText,
  onSelectIcon,
  onSelectAvatar,
  selectedAvatar,
  selectedCourseOrder,
  label,
  t,
}: Props): JSX.Element {
  const [openAvatarEditor, setOpenAvatarEditor] = useState(false);
  const [tempAvatar, setTempAvatar] = useState<
    File | string | undefined | null
  >();
  const [uploadedImg, setUploeadedImg] = useState<string | undefined | null>();
  const [avatarIsSelected, setAvatarIsSelected] = useState(false);

  const { isInfantil } = stages;
  const isInfantilCourse = isInfantil(selectedCourseOrder);

  const handleOpenAvatarEditor = (): void => {
    if (uploadedImg) setAvatarIsSelected(true);
    setOpenAvatarEditor(true);
  };

  const handleCloseAvatarEditor = (): void => {
    setTempAvatar(undefined);
    setOpenAvatarEditor(false);
  };

  const handleUploadAvatar = (imageFile: File | string | undefined): void => {
    setTempAvatar(imageFile);
  };

  const handleSaveImg = (image: string): void => {
    setUploeadedImg(image);
    setAvatarIsSelected(true);
    handleCloseAvatarEditor();
  };

  const handleSelectIcon = (id: number | string): void => {
    setAvatarIsSelected(false);
    onSelectIcon(id);
    onSelectAvatar(null);
  };

  useEffect(() => {
    if (avatarIsSelected && uploadedImg && uploadedImg !== selectedAvatar) {
      onSelectAvatar(uploadedImg);
    }
  }, [onSelectAvatar, uploadedImg, avatarIsSelected, selectedAvatar]);

  useEffect(() => {
    if (selectedAvatar && selectedAvatar !== uploadedImg) {
      setUploeadedImg(selectedAvatar);
      setAvatarIsSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAvatar]);

  useEffect(() => {
    if (!isInfantilCourse) {
      setTempAvatar(undefined);
      setUploeadedImg(undefined);
      setAvatarIsSelected(false);
      onSelectAvatar(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInfantilCourse]);

  return (
    <Container>
      {label && <Typography.Body2>{label}</Typography.Body2>}
      <div>
        {!!images.length && isInfantilCourse && (
          <ClassroomAvatar
            icon="AddPictureIcon"
            onClick={handleOpenAvatarEditor}
          />
        )}
        {images.map(({ id, image }) => (
          <ClassroomAvatar
            avatar={image}
            onClick={() => handleSelectIcon(id)}
            key={id}
            status={
              id === selectedIcon && !avatarIsSelected ? 'selected' : undefined
            }
          />
        ))}
        {!images.length && <Description>{noImageText}</Description>}
        <AvatarEditor
          open={openAvatarEditor}
          onClose={handleCloseAvatarEditor}
          onSaveImg={handleSaveImg}
          onUploadImg={handleUploadAvatar}
          img={tempAvatar || uploadedImg}
          isClassroom
          t={t}
        />
      </div>
    </Container>
  );
}
