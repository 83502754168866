import type { SVGProps } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { type IconType, Icons } from '@innovamat/glimmer-icons';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { StateLayer } from '../../utils/common.styled';

const Sizes = {
  XS: 0.5,
  S: 0.75,
  M: 1,
  L: 1.25,
  XL: 2,
} as const;

export type IconSize = keyof typeof Sizes;

type IconProps = {
  icon: IconType;
  size?: IconSize;
  iconColor?: string;
  bgColor?: string;
  hasStateLayer?: boolean;
} & React.SVGProps<SVGSVGElement>;

type IconContainerProps = {
  iconSize?: string;
  iconColor?: string;
  bgColor?: string;
  hasStateLayer?: boolean;
};

const IconContainer = styled.div<IconContainerProps>`
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: ${({ hasStateLayer }) => (hasStateLayer ? 'relative' : 'static')};

  ${({ iconSize }) =>
    css`
      width: ${iconSize};
      height: ${iconSize};
      min-width: ${iconSize};
      min-height: ${iconSize};
    `}

  ${({ bgColor }) =>
    bgColor &&
    css`
      path {
        fill: white;
      }
      background-color: ${bgColor};
    `}

  ${({ iconColor }) =>
    iconColor &&
    css`
      path {
        fill: ${iconColor};
      }
    `}

    .icon-stateLayer {
    border-radius: 50%;
  }

  :hover .icon-stateLayer {
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-hover-darker'].value};
  }

  :active .icon-stateLayer {
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-press-darker'].value};
  }
`;

export function Icon({
  icon,
  iconColor,
  bgColor,
  size = 'L',
  hasStateLayer,
  ...rest
}: IconProps): EmotionJSX.Element | null {
  const SelectedIcon = Icons[icon];

  const iconViewBox = `0 0 20 20`;
  const iconSize = `${Sizes[size]}rem`;

  if (SelectedIcon === undefined) {
    console.error(`Icon ${icon} not found`);
    return null;
  }

  return (
    <IconContainer
      iconSize={iconSize}
      iconColor={iconColor}
      bgColor={bgColor}
      hasStateLayer={hasStateLayer}
    >
      {hasStateLayer && <StateLayer className="icon-stateLayer" />}
      <SelectedIcon
        data-testid="icon"
        role="img"
        viewBox={iconViewBox}
        {...(rest as SVGProps<SVGSVGElement>)}
      />
    </IconContainer>
  );
}
