import { useTranslation } from 'react-i18next';

import {
  MinorText,
  ReportsTypography,
} from '../../../../components/common.styled';
import { FlexContainer } from '../../admin-dashboard.styled';
import PlaceholderCell from '../placeholder-cell';
import WarningIcon from '../warning-icon';

type Props = {
  connectedPercentage: number;
  classroomName: string;
  rowIsNull: boolean;
  courseOrder: number;
};

function ConnectedPercentage({
  connectedPercentage,
  classroomName,
  rowIsNull,
  courseOrder,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const WARNING_TRESHOLD = 50;

  const WARNING_TOOLTIP = t('reports.admin.connectedStudentRow.tooltip', {
    className: classroomName,
  });
  const hasWarning = connectedPercentage < WARNING_TRESHOLD;

  if (rowIsNull) return <PlaceholderCell left leftMargin={16} />;

  return (
    <FlexContainer>
      <ReportsTypography
        data-testid={`${courseOrder}_${classroomName}_STUDENTSPERCENTAGE`}
      >
        {connectedPercentage}
        <MinorText>%</MinorText>
      </ReportsTypography>
      {hasWarning && <WarningIcon tooltipText={WARNING_TOOLTIP} />}
    </FlexContainer>
  );
}

export default ConnectedPercentage;
