import styled from '@emotion/styled';
import { Typography } from '../typography';
import { Tooltip } from '../tooltip';
import { Icon } from '../icon';

export const AvatarCardWrapper = styled.div<{}>`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  // first child del elemento que sea
  & > *:first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Description = styled(Typography.Body3)`
  color: ${(props) =>
    props.theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export const StyledTooltip = styled(Tooltip)`
  width: 100%;
  overflow: hidden;
`;

export const ContentWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
  min-width: 0;
`;
