import { TableHead, TableRow } from '@mui/material';

import { ColumnWithActions, ExtendedRow, TableHeader } from '../table.types';
import {
  SubLabel,
  HeaderTableCellStyled,
  TableCellFirstRow,
  ExtendIcon,
  TableSortLabelWrapper,
  TableFirstRow,
  LabelWithIconArrow,
  Separator,
  SeparatorWrapper,
  Title,
  LabelInfoIcon,
  SortIcon,
  TitleContainer,
  SublabelContainer,
} from '../table.styled';

import {
  getColumnWidth,
  getTotalStickyWidthAfterIndex,
  getTotalStickyWidthBeforeIndex,
} from '../utils';
import { Tooltip } from '../../tooltip';
import { useGlimmerTheme } from '../../../theme';
import { InfoColumnIcon } from './info-column-icon';
import { LineSeparator } from './line-separator';
import { useLayoutEffect, useRef, useState } from 'react';

const MIN_LABEL_CHARACTERS = 15;

export function TableHeadWithSorting<T>({
  sortingOrder,
  sortingId,
  onRequestSort,
  onExtendColumn,
  extendableId,
  columns,
  subHeaderRow,
  id,
  backgroundColor,
}: TableHeader<T>): JSX.Element {
  const [biggestTitleHeight, setBiggestTitleHeight] = useState<number>(0);
  const [biggestSubtitleHeight, setBiggestSubtitleHeight] = useState<number>(0);
  const createSortHandler = (property: string): void => {
    onRequestSort(property);
  };

  const theme = useGlimmerTheme();

  const titleContainerRef = useRef<HTMLDivElement>(null);
  const subtitleContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const titleHeight = titleContainerRef?.current?.clientHeight || 16;
    const subtitleHeight = subtitleContainerRef?.current?.clientHeight || 16;

    if (titleHeight > biggestTitleHeight) {
      setBiggestTitleHeight(titleHeight);
    }

    if (subtitleHeight > biggestSubtitleHeight) {
      setBiggestSubtitleHeight(subtitleHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleContainerRef, subtitleContainerRef]);

  const renderValueRow = (
    column: ColumnWithActions<T>,
    value: string,
    row: ExtendedRow<T>
  ): string | any => {
    if (column.separator)
      return (
        <LineSeparator
          backgroundColor={backgroundColor}
          isDarkSeparator={column.isDarkSeparator}
        />
      );
    return column.render?.(value, row) || value;
  };

  const handleExtendBlockContentColumns =
    (id: string, extendable?: boolean) => () => {
      if (extendable) return onExtendColumn(id);
    };

  const getSubLabelText = (
    column: ColumnWithActions<T>
  ): string | JSX.Element | JSX.Element[] | undefined => {
    if (typeof column.subLabel === 'function') {
      return column.subLabel(column);
    }
    if (typeof column.subLabel === 'object') {
      return column.subLabel.text;
    }
    return column.subLabel;
  };

  const renderTitles = (column: ColumnWithActions<T>): JSX.Element => {
    const {
      sortable,
      dataTestId,
      id,
      label,
      labelTooltip,
      extendable,
      infoColumn,
      subLabel,
      isLargeLabel,
      ellipsisLines = 3,
    } = column;

    return (
      <>
        {sortable ? (
          <TableSortLabelWrapper
            ref={titleContainerRef}
            active={sortingId === id}
            direction={sortingId === id ? sortingOrder : 'asc'}
            onClick={() => createSortHandler(id)}
            IconComponent={() => (
              <SortIcon
                icon="ArrangeIcon"
                size="M"
                active={sortingId === id}
                direction={sortingOrder}
              />
            )}
            data-testid={`${id}_${dataTestId}`}
          >
            <LabelWithIconArrow
              largeLabel={
                isLargeLabel === undefined
                  ? label!.length > MIN_LABEL_CHARACTERS
                  : isLargeLabel
              }
            >
              {label}
            </LabelWithIconArrow>
          </TableSortLabelWrapper>
        ) : (
          <TitleContainer ref={titleContainerRef}>
            <Title
              data-testid={`${id}_${dataTestId}`}
              ellipsisLines={ellipsisLines}
              onClick={handleExtendBlockContentColumns(id, extendable)}
            >
              <Tooltip
                content={labelTooltip && labelTooltip}
                popperOptions={{ strategy: 'fixed' }}
              >
                <span>{label}</span>
              </Tooltip>
              {infoColumn && (
                <LabelInfoIcon>
                  <InfoColumnIcon tooltipText={infoColumn} />
                </LabelInfoIcon>
              )}
            </Title>

            {subLabel && renderSublabels(column)}
          </TitleContainer>
        )}
      </>
    );
  };

  const renderSublabels = (column: ColumnWithActions<T>): JSX.Element => {
    const { id, extendable, numberOfApplets, numberOfContents } = column;
    const appletsAvailable = numberOfApplets! > 0;
    const contentsAvailable = numberOfContents! > 0;
    const hasExtendableContent = appletsAvailable || contentsAvailable;
    const showExtendableIcon = extendable && hasExtendableContent;

    return (
      <SublabelContainer
        onClick={handleExtendBlockContentColumns(id, extendable)}
        isNotExtendableColumn={!extendable}
        hasExtendableContent={hasExtendableContent}
        ref={subtitleContainerRef}
      >
        <SubLabel color={theme.tokens.color.alias.cm.text['text-subtle'].value}>
          {getSubLabelText(column)}
        </SubLabel>
        {showExtendableIcon && (
          <ExtendIcon
            icon="OpenAppletsIcon"
            isOpenColumn={extendableId === id}
            size="S"
          />
        )}
      </SublabelContainer>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          if (column.isHiddenColumn) return null;

          const isSticky = column.sticky;
          const stickyPosition = column.stickyPosition;
          const left =
            isSticky && stickyPosition !== 'right'
              ? getTotalStickyWidthBeforeIndex(columns, index)
              : undefined;
          const right =
            isSticky && stickyPosition === 'right'
              ? getTotalStickyWidthAfterIndex(columns, index)
              : undefined;

          return (
            !column.isHiddenColumn && (
              <HeaderTableCellStyled
                hasSubheader={!!subHeaderRow}
                key={`header-${column.id}`}
                sortDirection={sortingId === column.id ? sortingOrder : false}
                isSticky={isSticky}
                stickyPosition={stickyPosition}
                left={left}
                right={right}
                style={{
                  width: getColumnWidth(column.width, column.id, extendableId),
                  maxWidth: getColumnWidth(
                    column.width,
                    column.id,
                    extendableId
                  ),
                  minWidth: column.minWidth || column.width,
                }}
                separator={column.separator}
                backgroundColor={backgroundColor}
                height={biggestTitleHeight + biggestSubtitleHeight}
              >
                {column.separator ? (
                  <SeparatorWrapper>
                    <Separator isDarkSeparator={column.isDarkSeparator} />
                  </SeparatorWrapper>
                ) : (
                  <>{renderTitles(column)}</>
                )}
              </HeaderTableCellStyled>
            )
          );
        })}
      </TableRow>

      {subHeaderRow && (
        <TableFirstRow>
          {columns.map((column, index) => {
            const value = subHeaderRow[column.id as keyof ExtendedRow<T>];
            const isSticky = column.sticky;
            const stickyPosition = column.stickyPosition;
            const left =
              isSticky && stickyPosition !== 'right'
                ? getTotalStickyWidthBeforeIndex(columns, index)
                : undefined;
            const right =
              isSticky && stickyPosition === 'right'
                ? getTotalStickyWidthAfterIndex(columns, index)
                : undefined;
            return (
              !column.isHiddenColumn && (
                <TableCellFirstRow
                  align={column.align}
                  colSpan={index === 0 ? 2 : 1}
                  isSticky={isSticky}
                  key={`row-${column.id}`}
                  left={left}
                  right={right}
                  separator={column.separator}
                  stickyPosition={stickyPosition}
                  style={{
                    minWidth: getColumnWidth(column.width, column.id),
                    display: index === 1 ? 'none' : '',
                  }}
                >
                  {renderValueRow(column, value, subHeaderRow)}
                </TableCellFirstRow>
              )
            );
          })}
        </TableFirstRow>
      )}
    </TableHead>
  );
}
