import styled from '@emotion/styled';
import { Typography } from '../typography';

import { InnerHtml } from '../inner-html';
import { ImageComponent } from '../image-component';
import { useEffect, useState } from 'react';
import { PlaceholderLogo } from './thumbnail-placeholder-logo';
import { getElevation } from '../elevation';

const MIN_ASPECT_RATIO = 1.5;
const MAX_ASPECT_RATIO = 1.8;

const Container = styled.div<{ isDisabled: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 180px;
  gap: 8px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  :hover .img-container {
    ${({ theme, isDisabled }) =>
      !isDisabled ? getElevation(theme, 'elevation 4') : ''}
  }
`;

const AppletImage = styled(ImageComponent)`
  aspect-ratio: 18/10;
  height: 110px;
  object-fit: fill;
  width: auto;
  border-radius: 4px;
`;

const AppletImagePlaceholder = styled.div`
  aspect-ratio: 18/10;
  height: 110px;
  width: auto;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.purple['04']};
  position: relative;
  overflow: hidden;
`;

const AppletImagePlaceholderText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-inverted'].value};

  background-color: #ce6da2;
`;

const Title = styled(Typography.Subtitle2)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type Props = {
  thumbnail: string;
  name: string;
  isDisabled: boolean;
  onClick: () => void;
};

const getThumbnailCode = (name: string) => {
  return name.split('-')[0];
};

export function AppletCard({ thumbnail, name, isDisabled, onClick }: Props) {
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      if (aspectRatio > MAX_ASPECT_RATIO || aspectRatio < MIN_ASPECT_RATIO)
        setShowPlaceholder(true);
    };
    img.src = thumbnail;
  }, [thumbnail]);

  return (
    <Container
      onClick={onClick}
      isDisabled={isDisabled}
      role="article"
      aria-label="applet card"
    >
      {showPlaceholder ? (
        <AppletImagePlaceholder className="img-container">
          <PlaceholderLogo />
          <AppletImagePlaceholderText>
            <InnerHtml text={getThumbnailCode(name)} />
          </AppletImagePlaceholderText>
        </AppletImagePlaceholder>
      ) : (
        <AppletImage src={thumbnail} className="img-container" />
      )}
      <div>
        <Title>
          <InnerHtml text={name} />
        </Title>
      </div>
    </Container>
  );
}
