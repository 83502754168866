import styled from '@emotion/styled';
import { ReportsTypography } from '../../../../components/common.styled';

type Props = {
  left?: boolean;
  leftMargin?: number;
};

const DoubleDash = styled(ReportsTypography)`
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
`;

const FlexContainer = styled.div<{ left?: boolean; leftMargin?: number }>`
  display: flex;
  justify-content: ${({ left }) => (left ? 'flex-start' : 'center')};
  align-items: center;
  margin-left: ${({ leftMargin }) => (leftMargin ? `${leftMargin}px` : '0px')};
`;

const PlaceholderCell = ({ left, leftMargin }: Props): JSX.Element => {
  return (
    <FlexContainer left={left} leftMargin={leftMargin}>
      <DoubleDash>--</DoubleDash>
    </FlexContainer>
  );
};
export default PlaceholderCell;
