import { gql } from 'graphql-request';

const AssetLocalization = gql`
        clock
				currency
				decimalseparator
				metricsystem
				numberwriting
				shortdivisionsign
				temperature
				verticalmultiplicationposition
        thousandseparator
        verticaldivisionalgorithm
        `;

const MediaFragment = gql`
  ancho_de_imagen
  media {
    _id
    _type
    url
    __typename
    name
    caption
    ${AssetLocalization}
  }
`;

const SmartGuideTable = gql`
  smartguidetable {
    table_cells {
      ... on SmartGuideTableCell {
        ancho_de_imagen
        header_cell
        image_cell_content {
          _id
          _type
          url
          __typename
          name
          caption
          ${AssetLocalization}
        }
        text_cell_content
      }
    }
    table_configuration {
      column_percentage
      table_columns
    }
  }
`;

const StepFragment = gql`

paso {
      __typename
      ... on Solucin {
        descripcin
        ${MediaFragment}
        ${SmartGuideTable}
      }
      ... on ConsejoDeGestin {
        descripcin
        ${MediaFragment}
        ${SmartGuideTable}
      }
      ... on Signedtip {
        tip_type
        description
        counselor
        ${MediaFragment}
        ${SmartGuideTable}
      }
      ... on PasoNumerado {
        numero_del_paso
        paso_evaluable
        enlace_al_evaluable
        nombre_del_evaluable
        dimensin_de_evaluacin
      }
      ... on Formacin {
        descripcin
        ${MediaFragment}
        ${SmartGuideTable}
      }
      ... on Ampliacin {
        descripcin
        ${MediaFragment}
        ${SmartGuideTable}
      }
      ... on Subactividad {
        texto
      }
      ... on Agrupacin {
        agrupacin
      }
      ... on QuePodemosObservar {
        ancho_de_imagen_3
        ancho_de_imagen_4
        ancho_de_imagen_5
        communication_and_representation
        connections
        media1 {
          _id
          _type
          url
          __typename
          caption
          ${AssetLocalization}
        }
        media2 {
          _id
          _type
          url
          __typename
          caption
          ${AssetLocalization}
        }
        media3 {
          _id
          _type
          url
          __typename
          caption
          ${AssetLocalization}
        }
        media4 {
          _id
          _type
          url
          __typename
          caption
          ${AssetLocalization}
        }
        media5 {
          _id
          _type
          url
          __typename
          caption
          ${AssetLocalization}
        }
        problem_resolution
        reasoning_and_test
        evaluacion_de_contenidos

      }
      ... on QuePodemosObservar {
        ancho_de_imagen_2
      }
      ... on QuePodemosObservar {
        ancho_de_imagen_1
      }
      ... on Apoyo {
        descripcin
        ${MediaFragment}
        ${SmartGuideTable}
      }
      ... on BloquesDeContenidosEspecficos {
        contenido_especfico
      }
      ... on BloquesDeContenidosEspecficos {
        bloque_de_contenido
      }
      ... on AntesDeComenzar {
        explicacin
      }
      ... on AntesDeComenzar {
        ${MediaFragment}
      }
      ... on BloquesDeContenido {
        numbers_and_operations
        space_and_shape
        statistics_and_probability
      }
      ... on BloquesDeContenido {
        measurement
      }
      ... on BloquesDeContenido {
        change_and_relationships
      }
      ... on Text {
        body
      }

      ... on ZzImagen {
        ${MediaFragment}
      }

      ... on TableSmartguides {
        table_title
        ${SmartGuideTable}
      }
    }
    actividad_evaluable
    dimensin_de_evaluacin {
      dimension_list
    }
    nombre_del_evaluable
    enlace_al_evaluable
    bloques_de_contenido_especficos {
      ... on BloquesDeContenidosEspecficos {
        bloque_de_contenido
        contenido_especfico
      }
      ... on BloquesDeContenido {
        measurement
        statistics_and_probability
        change_and_relationships
      }
      ... on BloquesDeContenido {
        space_and_shape
      }
      ... on BloquesDeContenido {
        numbers_and_operations
      }
    }
  
`;

const SmartGuidePart3 = gql`
  {
    __typename
    _id
    ttulo
    momento_clave
    resumen_en_una_lnea
    has_extension
    has_support
    has_support_player_id
		has_extension_player_id
    ${StepFragment}
  }
`;

const SmartGuidePart = gql`
  {
    __typename
    _id
    ttulo
    momento_clave
    resumen_en_una_lnea
    
    ${StepFragment}
  }
`;

const BloquesDeContenido = gql`
  bloques_de_contenido_resaltado {
    numbers_and_operations
    space_and_shape
    measurement
    change_and_relationships
    statistics_and_probability
  }
`;

const BloquesDeContenidoSecundaria = gql`
  bloques_de_contenido_resaltado {
    numbers_and_operations
    geometry
    statistics_and_probability
    algebra
  }
`;

const Dimensions = gql`
  dimensiones {
    problem_solving
    reasoning_and_proof
    connections
    communication_and_representation
  }
`;
const UsStandards = gql`
  us_standards {
    body
  }
`;
const AntesDeComenzar = gql`
  antes_de_comenzar {
    explicacin
    ${MediaFragment}
  }
`;
const Materiales = gql`
  materiales {
    ... on Projectable {
      projectable
    }

    ... on ZApplet {
      applet
    }
    ... on Video {
      video
    }
    ... on ClassMaterial {
      classroom_material
    }
    ... on DigitalResource {
      digital_resource
    }
    ... on MaterialEY {
      material_list
      additional_text
      optional
    }
    ... on MaterialPE {
      material_list
      optional
      additional_text
    }
    ... on MaterialSE {
      material_list
      additional_text
      optional
    }
  }
`;

const ParteSec = gql`
  parte {
    ... on SmartGuidePart1 {
      __typename
      _id
    }
    ... on SmartGuidePart2 {
      __typename
      _id
    }
    ... on SmartGuidePart3 { 
       __typename
      _id
    }
    ... on SmartGuidePartVideo {
      __typename
      _id
    }
  }
`;

const PartePri = gql`
  parte {
    ... on SmartGuidePart1 {
      __typename
      _id
    }
    ... on SmartGuidePart2 {
      __typename
      _id
    }
    ... on SmartGuidePart3 { 
       __typename
      _id
    }
  }
`;

const ParteTaller = gql`
  partes {
    ... on SmartGuidePart1 {
      __typename
      _id
    }
    ... on SmartGuidePart2 {
      __typename
      _id
    }
    ... on SmartGuidePart3 { 
       __typename
      _id
    }
  }
`;

const SmartGuideSecundariaDoc = gql`
  {
    _id
    title
    subtitle
    session_code {
      body
    }
    en_este_taller
    ${BloquesDeContenidoSecundaria}
    ${Dimensions}
    con_intencin_de
    vocabulario_clave
    ${AntesDeComenzar}
    ${Materiales}
    ${ParteSec}
  }
`;

const SmartGuidePrimariaDoc = gql`
  {
    _id
    title
    tipo
    subtitle
    session_code {
      body
    }
    en_este_taller
    ${BloquesDeContenido}
    ${Dimensions}
    ${UsStandards}
    con_intencin_de
    vocabulario_clave
    ${AntesDeComenzar}
    ${Materiales}
    ${PartePri}
  }
`;

const SmartGuideTalleresDoc = gql`
  {
    _id
    title
    subtitle
    session_code {
      body
    }
    en_este_taller
    ${BloquesDeContenido}
    ${Dimensions}
    ${UsStandards}
    con_intencin_de
    vocabulario_clave
    ${AntesDeComenzar}
    ${Materiales}
    ${ParteTaller}
  }
`;

const SmartGuidePartVideo = gql`
  {
    _id
    title
    should_translate

    video_series_name {
      chapter_duration
      seriename
    }

    video_chapter_content {
      ... on VideoChapterDescription {
        themes
        content
      }
    }
  }
`;

export const getSmartGuidePartDoc = (model: string) => {
  if (model === 'SmartGuidePartVideo') {
    return gql`
   query ($id: String, $locales: [String!]) {
    ${model}(id: $id, locales: $locales) ${SmartGuidePartVideo}
   }
  `;
  }

  if (model === 'SmartGuidePart3') {
    return gql`
   query ($id: String, $locales: [String!]) {
    ${model}(id: $id, locales: $locales) ${SmartGuidePart3}
   }
  `;
  }

  return gql`
   query ($id: String, $locales: [String!]) {
    ${model}(id: $id, locales: $locales) ${SmartGuidePart}
   }
  `;
};

export const getSmartGuideDoc = (model: string) => {
  const content = {
    SmartGuideTaller: SmartGuideTalleresDoc,
    SmartGuidePrimaria: SmartGuidePrimariaDoc,
    SmartGuideSecundaria: SmartGuideSecundariaDoc,
    SmartGuideOpen: SmartGuideSecundariaDoc,
  };

  return gql`
   query ($id: String, $locales: [String!]) {
    ${model}(id: $id, locales: $locales) ${
    content[model as keyof typeof content]
  }
   }
  `;
};
