
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ListIconComponent } from "./list-icon.svg";

export const ListIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ListIconComponent {...props} />;
};
