import { ToggleButton, Typography } from '@innovamat/glimmer-components';
import { useSessionDone } from '../../hooks/session-done-provider';
import { useGetApplets } from '../../hooks/use-get-applets';
import { useMarkSessionDone } from '../../hooks/use-mark-session-done';
import { useSessionFeedback } from '../../hooks/use-session-feedback';
import {
  ActivateSolutionsWrapper,
  Divider,
  Solutions,
} from './session-done-modal.styles';
import { UnlockModal } from './unlock-modal-content';
import UnlockedAppletsList from './unlocked-applets-list';

export function SessionDoneModal(): JSX.Element {
  const {
    currentSession,
    onCloseModal,
    showInModalOnlyRating,
    isModalOpen,
    t,
  } = useSessionDone();
  const { onToggleSolutions } = useMarkSessionDone();

  const { data: applets, isError, isPending } = useGetApplets();

  const {
    onChangeRating,
    onSendReview,
    ratingValue,
    hasAlreadyValue,
    onChangeReview,
    onErrorReview,
    isSendReviewPending,
    handleResetRating,
    review,
  } = useSessionFeedback();

  const renderApplets = (): JSX.Element | null => {
    return (
      <UnlockedAppletsList
        applets={applets}
        isError={isError}
        isLoadingApplets={isPending}
      />
    );
  };

  const renderSolutionSubtitle = (): JSX.Element => {
    if (!currentSession?.has_solutions) return <></>;
    return (
      <>
        <Solutions>
          <Typography.Body1>
            {t('session.unlockModal.subtitle.accessToActivities')}
          </Typography.Body1>
          <ActivateSolutionsWrapper>
            <Typography.Subtitle1>
              {t('session.unlockModal.includeSolutions')}
            </Typography.Subtitle1>
            <ToggleButton
              onToggle={(value) => {
                currentSession &&
                  onToggleSolutions({ resource: currentSession, value });
              }}
              isActive={currentSession?.solutions_enabled}
              dataTestId="activateSolutionsModal"
              aria-label="Activate solutions button"
            />
          </ActivateSolutionsWrapper>
        </Solutions>
        <Divider />
      </>
    );
  };

  return (
    <UnlockModal
      t={t}
      isOpen={isModalOpen}
      onClose={onCloseModal}
      handleSendReview={onSendReview}
      solutions={renderSolutionSubtitle()}
      applets={renderApplets()}
      handleRating={onChangeRating}
      ratingValue={ratingValue}
      handleReview={onChangeReview}
      handleErrorReview={onErrorReview}
      hasAlreadyValue={hasAlreadyValue}
      isOnlyRating={showInModalOnlyRating}
      isSendReviewPending={isSendReviewPending}
      handleResetRating={handleResetRating}
      review={review}
    />
  );
}
