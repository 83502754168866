import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import { Card } from '../../card/card';
import { Typography } from '../../typography';

const SlideDown = keyframes`
 0%{
    transform: translateY(-50px);
    opacity: 0;
  }
  100%{
    transform: translateY(0px);
    opacity: 1;
  }
`;

const SlideUp = keyframes`
 0%{
    transform: translateY(0px);
    opacity: 1;
  }
  100%{
    transform: translateY(-50px);
    opacity: 0;
  }
`;

type ContainerProps = {
  isOpened: boolean;
};

const Container = styled(Card)<ContainerProps>`
  position: absolute;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  bottom: 0;
  animation: ${({ isOpened }) => (isOpened ? SlideDown : SlideUp)} 0.2s forwards
    cubic-bezier(0.46, 0.03, 0.52, 0.96);
`;

const ContentWrapper = styled('div')`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding-top: 0.625rem;
  min-width: 100%;
`;

const CounterWrapper = styled(Typography.Subtitle2)(({ theme }) => {
  return css`
    background-color: ${theme.tokens.color.alias.cm.surface['surface-primary']
      .value};
    height: 1.25rem;
    min-width: 1.875rem;
    text-align: center;
    border-radius: ${theme.borders.radius.sm};
    line-height: 1.25rem;
  `;
});

export { Container, ContentWrapper, CounterWrapper };
