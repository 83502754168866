import { getAdminColumns } from '../admin-dashboard/utils/get-admin-columns';
import { useTranslation } from 'react-i18next';
import { useAdminDashboardQuery, Stages } from '@innovamat/glow-api-client';
import { getRowSpan } from '../admin-dashboard/utils/preprocess';
import ReportsLoader from '../../components/reports-loader';
import { TableComponent } from '@innovamat/glimmer-components';
import { ERROR_TYPE, ErrorPage } from '../../../navigation';
import { DashboardProps } from '../admin-dashboard';

export const SecondaryAdminDashboard = ({
  organizationId,
  tabsAreLoaded,
  goToClassroom,
}: DashboardProps): JSX.Element => {
  const {
    data: dataSE,
    isLoading: loadingSE,
    isError,
  } = useAdminDashboardQuery(
    {
      organizationId: organizationId!,
      stage: 'SE' as Stages,
    },
    { enabled: !!organizationId }
  );

  const { t } = useTranslation();
  const columns = getAdminColumns({ t, goToClassroom });

  if (isError) {
    return <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />;
  }

  return (
    <>
      {loadingSE || !tabsAreLoaded ? (
        <ReportsLoader />
      ) : (
        <TableComponent
          id={'ADRE_SECONDARY'}
          columns={columns}
          rows={dataSE?.adminDashboard ? getRowSpan(dataSE.adminDashboard) : []}
          topStickyOffset={64}
        />
      )}
    </>
  );
};
