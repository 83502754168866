import styled from '@emotion/styled';
import type { IconType } from '@innovamat/glimmer-icons';
import {
  createTheme,
  Popover as PopoverMaterial,
  type PopoverProps,
} from '@mui/material';
import { ThemeProvider as MaterialProvider } from '@mui/material/styles';
import { createContext, type ReactNode, useContext, useState } from 'react';
import { useGlimmerTheme } from '../../theme';
import { StateLayer } from '../../utils/common.styled';
import { IconBase } from '../icon-base';
import { Typography } from '../typography';
import { CustomButton, PopoverItemContainer, Subtitle } from './popover.styled';

const PopoverContent = styled.div``;

type Props = {
  id?: string;
  trigger: ReactNode;
  children: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  popoverProps?: Omit<PopoverProps, 'open' | 'anchorEl' | 'onClose'>;
  closeOnSelectItem?: boolean;
  openOnHover?: boolean;
  style?: React.CSSProperties;
};

export const POPOVER_STATES = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
  SELECTED: 'selected',
} as const;

export type PopoverState = (typeof POPOVER_STATES)[keyof typeof POPOVER_STATES];

const PopoverContext = createContext<{
  open: boolean;
  closeOnSelectItem?: boolean;
  onClose: () => void;
}>({
  open: false,
  closeOnSelectItem: true,
  onClose: () => {},
});

const usePopoverContext = () => useContext(PopoverContext);

function Popover({
  id,
  trigger,
  children,
  popoverProps,
  closeOnSelectItem = true,
  openOnHover = false,
  style,
}: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = useState(null);

  const materialTheme = createTheme({});
  const glimmerTheme = useGlimmerTheme();

  const theme = {
    ...materialTheme,
    breakpoints: {
      ...materialTheme.breakpoints,
      ...glimmerTheme.breakpoints,
    },
  };

  const handleClick = (event: any) => {
    if (openOnHover) return;
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event: any) => {
    if (openOnHover) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    if (openOnHover) setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <MaterialProvider theme={theme}>
      <PopoverContext.Provider
        value={{ onClose: handleClose, open, closeOnSelectItem }}
      >
        <div
          onClick={handleClick}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={style}
        >
          {trigger}
        </div>
        <PopoverMaterial
          onClick={(e) => e.stopPropagation()}
          id={open ? id : undefined}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          {...popoverProps}
        >
          <PopoverContent>{children}</PopoverContent>
        </PopoverMaterial>
      </PopoverContext.Provider>
    </MaterialProvider>
  );
}

type PopoverItemProps = {
  children: ReactNode;
  subtitle?: ReactNode | string;
  state: PopoverState;
  icon?: IconType;
  onSelectItem?: () => void;
  customStyles?: string;
  dataTestId?: string;
};

const PopoverItem = ({
  children,
  subtitle,
  state,
  icon,
  onSelectItem,
  customStyles,
  dataTestId,
  ...rest
}: PopoverItemProps) => {
  const { onClose, closeOnSelectItem } = usePopoverContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onSelectItem?.();

    if (closeOnSelectItem) {
      onClose();
    }
  };

  return (
    <PopoverItemContainer
      className="dropdownItem"
      customStyles={customStyles}
      data-testid={dataTestId}
      onClick={handleClick}
      role="option"
      state={state}
      {...rest}
    >
      {state !== 'disabled' && (
        <StateLayer className="dropdownItem-stateLayer" />
      )}
      {icon && (
        <IconBase
          icon={icon}
          size="M"
          isHoverEnabled={false}
          className="menuIcon"
        />
      )}
      <div>
        <Typography.Body2>{children}</Typography.Body2>
        {subtitle && <Subtitle state={state}>{subtitle}</Subtitle>}
      </div>
    </PopoverItemContainer>
  );
};

type PopoverButtonProps = {
  children: ReactNode;
  dataTestId?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const PopoverButton = ({
  dataTestId,
  onClick,
  disabled,
  children,
}: PopoverButtonProps) => {
  const { open } = usePopoverContext();

  return (
    <CustomButton
      data-testid={dataTestId}
      state={open ? 'selected' : 'active'}
      variant="secondary"
      loading={false}
      rightIcon="ExpandMoreIcon"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </CustomButton>
  );
};

Popover.Item = PopoverItem;
Popover.Button = PopoverButton;

export { Popover };
