import { useState, useEffect } from 'react';
import type ReactApexChart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { Loader } from '../loader';

type ChartType =
  | 'area'
  | 'line'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap';

type ChartProps = {
  options: ApexOptions;
  series: {
    name: string;
    data: number[];
  }[];
  type: ChartType;
  width: number | string;
  height: string;
  id: string;
  'data-testid'?: string;
};

const ApexChartWrapper = ({
  options,
  series,
  type,
  width,
  height,
  id,
  loadingComponent,
  'data-testid': dataTestId,
}: ChartProps & { loadingComponent?: JSX.Element }): JSX.Element => {
  const [ApexChartsComponent, setApexChartsComponent] = useState<
    typeof ReactApexChart | null
  >(null);

  useEffect(() => {
    // Dynamic import for react-apexcharts
    import('react-apexcharts').then((module) => {
      setApexChartsComponent(() => module.default);
    });
  }, []);

  // Fallback for React SPA or client-side rendering
  if (!ApexChartsComponent) {
    if (loadingComponent) return loadingComponent;
    return (
      <div
        style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <Loader />
      </div>
    );
  }
  // Using the dynamically imported ApexCharts component
  return (
    <ApexChartsComponent
      options={options}
      series={series}
      type={type}
      width={width}
      height={height}
      id={id}
      data-testid={dataTestId}
    />
  );
};

export { ApexChartWrapper };
