import { useTranslation } from 'react-i18next';
import { ProgressCounter } from '../../../../components/progress-counter';

type Props = {
  completed: number;
  rowIsNull: boolean;
  lab?: number;
  adv?: number;
  total: number;
  classroomName: string;
  courseOrder: number;
};

function CompletedSessions({
  completed,
  total,
  lab,
  adv,
  rowIsNull,
  courseOrder,
  classroomName,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const getTooltipContent = (): JSX.Element | undefined => {
    if (!lab && !adv) return;
    return (
      <>
        {!!lab && (
          <p>
            {t('reports.admin.session.lab')}: {lab}
          </p>
        )}
        {!!adv && (
          <p>
            {t('reports.admin.session.adv')}: {adv}
          </p>
        )}
      </>
    );
  };

  return (
    <ProgressCounter
      completed={completed}
      total={total}
      rowIsNull={rowIsNull}
      dataTestId={`${courseOrder}_${classroomName}_COMPLETEDSESSIONS`}
      tooltip={getTooltipContent()}
    />
  );
}

export default CompletedSessions;
