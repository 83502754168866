import { useLayoutEffect } from 'react';
import {
  distanceToTop,
  getScrollParent,
  handleStickyHorizontalScroll,
} from '../utils';

const useStickyHeaderHandler = (
  id: string,
  topStickyOffset: number,
  hasFirstRow: boolean
): void => {
  useLayoutEffect(() => {
    const appContent = getScrollParent(document.getElementById(id));
    const stickyHeader = document.getElementById(`sticky-header+${id}`);
    let lastScrollTop = 0;

    if (stickyHeader) {
      stickyHeader.style.display = 'none';
    }

    const tableElement = document.getElementById(`table+${id}`);
    const tableContainer = document.getElementById(id);
    const parentHeight = tableContainer!.parentElement!.clientHeight;

    const onScroll = (): void => {
      handleStickyHorizontalScroll(id);

      if (!appContent) return;

      const scrollTop = appContent.scrollTop;

      if (!stickyHeader || !tableElement || !tableContainer) return;

      const distance = distanceToTop(tableElement, appContent);
      if (!distance) return;
      const firstRowOffset = stickyHeader!.clientHeight;

      if (scrollTop > lastScrollTop && distance < topStickyOffset) {
        const stickyHeaderTopPosition = `${topStickyOffset}px`;
        Object.assign(stickyHeader.style, {
          display: 'table-header-group',
          position: 'sticky',
          left: '0',
          zIndex: '18',
          top: stickyHeaderTopPosition,
        });

        tableContainer.style.transform = `translateY(-${stickyHeader.clientHeight}px)`;
        tableContainer.parentElement!.style.maxHeight = `${parentHeight}px`;

        if (hasFirstRow) {
          tableContainer.parentElement!.style.maxHeight = `${parentHeight}px`;
        }
      }
      if (
        scrollTop < lastScrollTop &&
        distance > topStickyOffset + firstRowOffset
      ) {
        stickyHeader.style.display = 'none';
        tableContainer.style.transform = `translateY(0px)`;
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [id, hasFirstRow, topStickyOffset]);
};

export default useStickyHeaderHandler;
