import { type Region, REGIONS } from './_generated/locales';

// Generates a map of regions from the Regions imported from Apollo server
export const REGIONS_MAP = REGIONS.reduce(
  (result, regionCode: Region) => ((result[regionCode] = regionCode), result),
  {} as Record<Region, Region>
);

export const isCaliforniaRegion = (region: Region): boolean =>
  region === REGIONS_MAP['US-EDR'];
