import { getAdminColumns } from '../admin-dashboard/utils/get-admin-columns';
import { useTranslation } from 'react-i18next';
import ReportsLoader from '../../components/reports-loader';
import { useAdminDashboardQuery, Stages } from '@innovamat/glow-api-client';
import { getRowSpan } from '../admin-dashboard/utils/preprocess';
import { TableComponent } from '@innovamat/glimmer-components';
import { ERROR_TYPE, ErrorPage } from '../../../navigation';

type Props = {
  organizationId?: string;
  tabsAreLoaded?: boolean;
  goToClassroom?: (classroomId: string) => void;
};

export const PrimaryAdminDashboard = ({
  organizationId,
  tabsAreLoaded,
  goToClassroom,
}: Props): JSX.Element => {
  const {
    data: dataPE,
    isLoading: loadingPE,
    isError,
  } = useAdminDashboardQuery(
    {
      organizationId: organizationId!,
      stage: 'PE' as Stages,
    },
    { enabled: !!organizationId }
  );
  const { t } = useTranslation();

  const columns = getAdminColumns({ t, goToClassroom });

  if (isError) {
    return <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />;
  }

  return (
    <>
      {loadingPE || !tabsAreLoaded ? (
        <ReportsLoader />
      ) : (
        <TableComponent
          id={'ADRE_PRIMARY'}
          columns={columns}
          rows={dataPE?.adminDashboard ? getRowSpan(dataPE.adminDashboard) : []}
          topStickyOffset={64}
        />
      )}
    </>
  );
};
