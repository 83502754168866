import styled from '@emotion/styled';
import React, {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDevice } from '../../hooks/useDevice';
import { IconButton } from '../icon-button';

const Container = styled.div<{ gap?: string }>`
  position: relative;

  &:hover .previous-button,
  &:hover .next-button {
    opacity: 1;
  }
`;

const SliderItem = styled.div<{ opacity: number; imgHeight?: number }>`
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.3s ease-in-out;
  position: relative;

  & img.failed-load {
    min-height: ${({ imgHeight }) =>
      imgHeight !== 0 ? `${imgHeight}px` : '148px'};
  }
`;

const SliderButton = styled(IconButton)<{
  top?: number;
  show: boolean;
  gap?: string;
}>`
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : '50%')};

  z-index: 1;
  opacity: 0;
  display: ${({ show }) => (show ? 'block' : 'none')};
  transition: opacity 0.3s ease-in-out;
`;

const PreviousButton = styled(SliderButton)`
  left: 0;
  transform: translate(-50%, -50%);
`;

const NextButton = styled(SliderButton)<{ gap?: string }>`
  right: ${({ gap }) => `calc(5% + ${gap})`};
  transform: translate(50%, -50%);
`;

const Slides = styled.div<{
  translateX: number;
  gap?: string;
  slidesToShow: number;
  isTouchable?: boolean;
  hasScroll?: boolean;
}>`
  transform: ${({ translateX, isTouchable }) =>
    isTouchable ? `translateX(0px)` : `translateX(${translateX}px)`};
  align-items: flex-start;
  gap: ${({ gap }) => gap || '0'};
  display: flex;
  width: ${({ isTouchable }) => (isTouchable ? '100%' : '95%')};
  overflow-x: ${({ isTouchable }) => (isTouchable ? 'scroll' : '')};
  padding-right: ${({ gap, isTouchable, hasScroll }) =>
    isTouchable && hasScroll ? gap : '0'};

  .slide {
    min-width: 100%;
    height: 100%;
  }

  .slider-item {
    min-width: ${({ gap, slidesToShow, isTouchable }) =>
      isTouchable
        ? `calc(90% / ${slidesToShow || 4} - ${gap})`
        : `calc(100% / ${slidesToShow || 4} - ${gap})`};
    width: ${({ gap, slidesToShow, isTouchable }) =>
      isTouchable
        ? `calc(90% / ${slidesToShow || 4} - ${gap})`
        : `calc(100% / ${slidesToShow || 4} - ${gap})`};

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  transition: transform 0.5s ease-in-out;
`;

const MemoizedSliderItem = React.memo(SliderItem);

type Props = {
  children: React.ReactNode;
  initialIndex?: number;
  gap?: string;
  slidesToShow?: number;
  lowerOpacityOnHiddenItem?: boolean;
  onArrowClick?: () => void;
};

export function Slider({
  children,
  gap,
  initialIndex,
  slidesToShow = 4,
  lowerOpacityOnHiddenItem = true,
  onArrowClick,
}: Props): JSX.Element {
  const { isTouchableMobileOrTablet } = useDevice();
  const containerRef = useRef<HTMLDivElement>(null);
  const totalSlides = Children.count(children);
  const [imageHeight, setImageHeight] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [translateX, setTranslateX] = useState(0);

  const handleInitialIndex = useCallback(
    (initialIndex?: number): number => {
      if (!initialIndex) return 0;
      return Math.max(0, Math.min(initialIndex, totalSlides - slidesToShow));
    },
    [totalSlides, slidesToShow]
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!initialIndex) return;
    if (currentIndex > 0) return;

    setCurrentIndex(handleInitialIndex(initialIndex));
  }, [initialIndex]);

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth;
    const slideWidth = containerWidth ? containerWidth / slidesToShow : 0;
    setTranslateX(slideWidth * currentIndex * -1);
  }, [currentIndex, slidesToShow]);

  const handleImageHeight = useCallback((): void => {
    const imgElementLoaded =
      containerRef.current?.querySelector('.correct-load');

    if (imgElementLoaded) {
      const imageHeight = imgElementLoaded.clientHeight;
      setImageHeight(imageHeight);
    }
  }, []);

  useEffect(() => {
    handleImageHeight();
  }, [slidesToShow, loaded, handleImageHeight]);

  const handleNextClick = useCallback((): void => {
    onArrowClick?.();
    const newIndex = Math.min(
      currentIndex + slidesToShow,
      totalSlides - slidesToShow
    );
    setCurrentIndex(newIndex);
  }, [currentIndex, slidesToShow, totalSlides]);

  const handlePreviousClick = useCallback((): void => {
    onArrowClick?.();
    setCurrentIndex(Math.max(currentIndex - slidesToShow, 0));
  }, [currentIndex, slidesToShow]);

  const getSliderItemOpacity = (index: number): number => {
    if (!lowerOpacityOnHiddenItem) return 1;

    return index >= currentIndex && index < currentIndex + slidesToShow
      ? 1
      : 0.5;
  };

  const showPreviousButton = currentIndex > 0;
  const showNextButton = currentIndex + slidesToShow < totalSlides;

  return (
    <Container>
      {!isTouchableMobileOrTablet && (
        <PreviousButton
          show={showPreviousButton}
          isRounded
          icon="PreviousIcon"
          onClick={handlePreviousClick}
          top={imageHeight / 2}
          className="previous-button"
        />
      )}

      <Slides
        ref={containerRef}
        translateX={translateX}
        gap={gap}
        slidesToShow={slidesToShow}
        isTouchable={isTouchableMobileOrTablet}
        hasScroll={showNextButton || showPreviousButton}
      >
        {Children.map(children, (child, index) => (
          <MemoizedSliderItem
            key={index}
            className="slider-item"
            opacity={getSliderItemOpacity(index)}
            imgHeight={imageHeight}
          >
            {cloneElement(child as React.ReactElement, {
              onLoad: (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget
                  .querySelector('img')
                  ?.classList.add('correct-load');
                setLoaded(true);
              },
              onError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget
                  .querySelector('img')
                  ?.classList.add('failed-load');
              },
            })}
          </MemoizedSliderItem>
        ))}
      </Slides>

      {!isTouchableMobileOrTablet && (
        <NextButton
          show={showNextButton}
          gap={gap}
          isRounded
          icon="NextIcon"
          onClick={handleNextClick}
          top={imageHeight / 2}
          className="next-button"
        />
      )}
    </Container>
  );
}
