
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as TouchIconComponent } from "./touch-icon.svg";

export const TouchIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <TouchIconComponent {...props} />;
};
