import { useState } from 'react';
import { useUser } from '../../hooks/use-user';
import { FullScreenLogoAnimation } from '../full-screen-logo-animation';
import StudentWithoutAccess from '../StudentWithoutAccess';

const HomeLayout = () => {
  const { currentClassroom, isRole } = useUser();

  const classroomName = currentClassroom?.name;
  const hasClassroom = classroomName !== null && classroomName !== '';
  const loadingHasClassroom = classroomName === undefined;
  const [redirectCalled, setRedirectCalled] = useState(false);
  const referral = new URLSearchParams(window.location.search).get('referral');

  if (isRole.Student && !loadingHasClassroom && !hasClassroom) {
    return <StudentWithoutAccess />;
  }

  if (!redirectCalled) {
    const currentUrl = window.location.href;
    const pattern = /https:\/\/(pr-[a-zA-Z0-9]+)\.app\.innovamat\.cloud\/?/;
    const match = currentUrl.match(pattern);

    if (referral) {
      window.location.replace(referral);
    } else {
      if (match) {
        const newUrl = `https://${match[1]}.manager.pre.innovamat.cloud`;
        window.location.replace(newUrl);
      } else {
        window.location.replace(import.meta.env.VITE_MANAGER_NEXT as string);
      }
    }

    setRedirectCalled(true);
  }
  return <FullScreenLogoAnimation />;
};

export default HomeLayout;
