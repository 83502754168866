import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Tippy, { type TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { getTypography } from '../typography';
import { useDevice } from '../../hooks/useDevice';

const StyledTippy = styled(Tippy)`
  ${({ theme }) => {
    return css`
      ${getTypography(theme, 'Body 3')}
      background-color: ${theme.tokens.color.alias.cm.bg['bg-neutral-inverted']
        .value};
      color: ${theme.tokens.color.alias.cm.text['text-inverted'].value};
      border-radius: 4px;
    `;
  }}
`;

const StyledSpanBase = styled.span`
  display: inline-block;
`;

const StyledSpan = styled(StyledSpanBase)`
  width: auto;
  > * {
    height: 100%;
  }
`;

type Props = {
  hasChildrenWithEllipsis?: boolean;
  source?: any;
  target?: any;
} & TippyProps;

export function Tooltip({
  children,
  content,
  delay = [400, 100],
  disabled = false,
  hasChildrenWithEllipsis = false,
  source,
  target,
  theme,
  ...rest
}: Props): JSX.Element {
  const { isTouchable } = useDevice();
  const wrapChildren = (): JSX.Element | undefined => {
    if (hasChildrenWithEllipsis) {
      return <StyledSpanBase>{children}</StyledSpanBase>;
    }

    if (
      typeof children?.type === 'function' ||
      typeof children?.type === 'object'
    ) {
      return <StyledSpan className={rest.className}>{children}</StyledSpan>;
    }

    return children;
  };

  if (!content && !source) {
    return <>{children}</>;
  }

  if (source) {
    return (
      <StyledTippy
        singleton={source}
        delay={delay}
        arrow={false}
        touch={false}
        {...rest}
      />
    );
  }

  return (
    <StyledTippy
      singleton={target}
      disabled={disabled || isTouchable}
      content={content}
      delay={delay}
      arrow={false}
      offset={[0, 4]}
      touch={false}
      {...rest}
    >
      {wrapChildren()}
    </StyledTippy>
  );
}
