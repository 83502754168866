import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from '../../theme';
import type { State } from './dropdown';
import { Button } from '../button';
import { type ElevationType, getElevation } from '../elevation';
import { Typography } from '../typography';

const DropdownContainer = styled.div<{ disabled: boolean; fill?: boolean }>`
  position: relative;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
  ${({ fill }) =>
    fill &&
    css`
      width: 100%;
    `}
`;

const DropdownToggleContainer = styled.div`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const DropdownContentContainer = styled.div<{
  position?: 'bottomLeft' | 'bottomRight' | 'topRight' | 'topLeft';
  offset?: number;
  hasScroll?: boolean;
  backgroundColor?: string;
  borderRadius?: string;
  elevation: ElevationType;
  contentSpace: string;
  customStyles?: string;
  isOpen?: boolean;
}>`
  min-width: 232px;
  max-width: 90vw;
  flex-direction: column;
  border-radius: ${({ borderRadius }) => borderRadius};
  position: absolute;
  z-index: 32;
  margin: 0;
  padding: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: 0;
  pointer-events: none;
  transform: ${({ position }) => {
    if (position === 'bottomLeft') {
      return 'translateY(-8px)';
    }
    if (position === 'bottomRight') {
      return 'translateY(-8px)';
    }
    if (position === 'topRight') {
      return 'translateY(8px)';
    }
    if (position === 'topLeft') {
      return 'translateY(8px)';
    }
    return 'none';
  }};
  height: 0px !important;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex !important;
      pointer-events: auto;
      opacity: 1;
      height: auto !important;
      overflow: visible;
      transform: translateY(0);
      transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
    `}

  // only transition back in the bottom positions cause height is auto and can't be transitioned
  ${({ position }) =>
    position === 'bottomLeft' ||
    (position === 'bottomRight' &&
      css`
        transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
      `)}

  > div {
    :first-of-type {
      border-top-left-radius: ${({ borderRadius }) => borderRadius};
      border-top-right-radius: ${({ borderRadius }) => borderRadius};
      .dropdownItem-stateLayer {
        border-top-left-radius: ${({ borderRadius }) => borderRadius};
        border-top-right-radius: ${({ borderRadius }) => borderRadius};
      }
    }
    :last-of-type {
      border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
      border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
      .dropdownItem-stateLayer {
        border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
        border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
      }
    }
  }

  ${({ position, offset }) =>
    position === 'bottomLeft' &&
    css`
      top: 100%;
      left: ${offset || 0}px;
    `}
  ${({ position, offset }) =>
    position === 'bottomRight' &&
    css`
      top: 100%;
      right: ${offset || 0}px;
    `}
  ${({ position, offset }) =>
    position === 'topRight' &&
    css`
      bottom: 100%;
      right: ${offset || 0}px;
    `}
  ${({ position, offset }) =>
    position === 'topLeft' &&
    css`
      bottom: 100%;
      left: ${offset || 0}px;
    `}

    ${({ hasScroll, borderRadius, theme, elevation, contentSpace }) => {
    return (
      !hasScroll &&
      css`
        ::before {
          content: '';
          display: block;
          height: ${contentSpace};
          background-color: transparent; // transparent space
        }
        ::after {
          content: '';
          position: absolute;
          top: 4px; // Position 4px down and cover all the content
          left: 0;
          right: 0;
          bottom: 0;
          ${getElevation(theme, elevation)}
          border-radius: ${borderRadius};
          pointer-events: none; // Make sure this component is only to add the shadow
        }
      `
    );
  }}
  

  ${({ customStyles }) => customStyles}
`;

const DropdownItemContainer = styled.div<{
  state: State;
  customStyles?: string;
}>`
  min-width: 100%;
  width: max-content;
  background-color: ${theme.tokens.color.global.white.value};
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 14px 16px;
  max-height: 48px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  ${({ state, theme }) =>
    state === 'active'
      ? css`
          &:hover .dropdownItem-stateLayer {
            background-color: ${theme.tokens.color.specific['state-layer'][
              'state-hover-darker'
            ].value};
          }

          &:active .dropdownItem-stateLayer {
            background-color: ${theme.tokens.color.specific['state-layer'][
              'state-press-darker'
            ].value};
          }
        `
      : css`
          svg,
          path {
            fill: ${state === 'disabled'
              ? theme.tokens.color.alias.cm.icon['icon-disabled'].value
              : theme.tokens.color.alias.cm.icon['icon-default'].value};
          }
        `}

  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${theme.tokens.color.alias.cm.text['text-disabled'].value};
    `}
`;

const DropdownButton = styled(Button)<{ state: State; disabled?: boolean }>`
  ${({ state }) =>
    state === 'selected' &&
    css`
      .button-stateLayer {
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-hover-darker'
        ].value};
      }
    `}
`;

// components below only needed for storybook
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
`;

const ResourceNavContainer = styled.div`
  display: flex;
  align-items: start;
  margin-left: auto;

  .resourceNavigationContent {
    background-color: white;
    border: 1px solid #ddd;

    width: 388px;
    max-height: 368px;
    padding: 0 24px 24px 24px;
    overflow-y: auto;
    gap: 16px;
    .supertitle {
      padding: 4px 0;
    }
  }
`;

const ToggleWrapper = styled.button`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: #ffffff;

  text-align: left;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  background-color: #fff;
  padding: 12px;
`;

const StickyTitle = styled.div`
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 11;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Divider = styled.div`
  margin-top: -11px;
  width: 323px;
  height: 0px;
  border-bottom: 1px solid #ededed;
`;

const NavigationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Subtitle = styled(Typography.Body3)<{ state: State }>`
  margin-top: -2px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  ${({ state }) =>
    state === 'disabled' &&
    css`
      color: ${theme.tokens.color.alias.cm.text['text-disabled'].value};
    `}
`;

export {
  DropdownContainer,
  DropdownToggleContainer,
  DropdownContentContainer,
  DropdownItemContainer,
  DropdownButton,
  Wrapper,
  ToggleWrapper,
  ContentWrapper,
  StickyTitle,
  Divider,
  NavigationListWrapper,
  ResourceNavContainer,
  Subtitle,
};
