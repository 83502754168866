import { useMemo } from 'react';

import { useUser } from '@innovamat/ga-features';
import { useOrganizationQuery } from '@innovamat/glow-api-client';
import { sortBy, stages } from '@innovamat/radiance-utils';

import {
  type Classroom,
  type Course,
  useCoursesQuery,
  useOrganizationsCoursesQuery,
} from '../../generated/types';

type Props = {
  activeLevel?: 'MANAGER' | 'DEFAULT';
  nextAcademicYearId?: string;
  organizationId?: string;
  regionCode: string;
};

type GroupedCourses = {
  stage: string;
  courses: Course[];
};

export type SelectCourse = Course & { available: boolean };

type useCoursesValue = {
  allCourses: Course[];
  courses: Course[];
  coursesForSelectCoursePage: SelectCourse[];
  isLoading: boolean;
  isSuccess: boolean;
  getCourseByCourseOrder: (courseOrder: number) => Course | undefined | null;
  getCourseById: (courseId: string) => Course | undefined;
  getYearUpdateCourse: (classData: Classroom) => string | undefined;
  groupCoursesByStage: (courses: Course[]) => GroupedCourses[];
  toOptions: (_courses: Course[]) => { label: string; value: string }[];
  validCoursesByCourse: (courseOrder: number | null) => Course[];
};

export function useCourses({
  activeLevel,
  nextAcademicYearId,
  organizationId,
  regionCode,
}: Props): useCoursesValue {
  const { isRole } = useUser();
  const isLead = isRole.Lead;

  const { data: { organization } = {} } = useOrganizationQuery(
    { id: organizationId ?? '' },
    { enabled: Boolean(organizationId), staleTime: Infinity }
  );

  const { data: allCoursesData, isSuccess: isCoursesSuccess } = useCoursesQuery(
    { regionCode: regionCode, activeLevel },
    { enabled: !!regionCode, staleTime: Infinity }
  );

  const {
    data: organizationsCoursesData,
    isLoading,
    isSuccess: isOrganizationCoursesSuccess,
  } = useOrganizationsCoursesQuery(
    {
      organizationId: organizationId!,
      academicYearId: organization?.academicYearId!,
    },
    {
      enabled: Boolean(organizationId) && Boolean(organization?.academicYearId),
      staleTime: Infinity,
    }
  );

  const { data: nextAcademicYearOrganizationsCoursesData } =
    useOrganizationsCoursesQuery(
      { organizationId: organizationId!, academicYearId: nextAcademicYearId! },
      {
        enabled: Boolean(organizationId) && Boolean(nextAcademicYearId),
        staleTime: Infinity,
      }
    );

  const organizationsCoursesIds = useMemo(() => {
    return (
      organizationsCoursesData?.organizationsCourses?.map(
        (organizationCourse) => organizationCourse?.id
      ) || []
    );
  }, [organizationsCoursesData]);

  const nextAcademicYearOrganizationsCoursesIds = useMemo(() => {
    return (
      nextAcademicYearOrganizationsCoursesData?.organizationsCourses?.map(
        (organizationCourse) => organizationCourse?.id
      ) || []
    );
  }, [nextAcademicYearOrganizationsCoursesData]);

  const coursesForSelectCoursePage = useMemo(
    () =>
      (allCoursesData
        ?.courses!.map((course) => ({
          ...course,
          available: isLead
            ? true
            : organizationsCoursesIds.includes(course?.id) ||
              nextAcademicYearOrganizationsCoursesIds.includes(course?.id),
        }))
        .sort(sortBy('order')) as SelectCourse[]) || [],
    [
      allCoursesData?.courses,
      nextAcademicYearOrganizationsCoursesIds,
      organizationsCoursesIds,
      isLead,
    ]
  );

  const courses = useMemo(
    () =>
      (organizationsCoursesData?.organizationsCourses
        ?.concat()
        .sort(sortBy('order')) || []) as Course[],
    [organizationsCoursesData?.organizationsCourses]
  );

  const allCourses = useMemo(
    () =>
      (allCoursesData?.courses?.concat().sort(sortBy('order')) ||
        []) as Course[],
    [allCoursesData?.courses]
  );

  const validCoursesByCourse = (courseOrder: number | null): Course[] => {
    const isSecundariaCourse = stages.isSecundaria(courseOrder);
    const filteredCourses = courses.filter((course) => {
      if (!courseOrder) return true;
      return isSecundariaCourse
        ? stages.isSecundaria(course?.order)
        : !stages.isSecundaria(course?.order);
    });
    return filteredCourses;
  };

  const toOptions = (_courses: Course[]) => {
    return (
      _courses?.map((course) => ({
        label: course?.name!,
        value: course?.id!,
      })) || []
    );
  };

  const groupCoursesByStage = (courses: Course[]): GroupedCourses[] => {
    const groupedCourses = courses.reduce(
      (result: GroupedCourses[], course) => {
        const courseOrder = course.order;
        const stage = stages.getStageFromOrder(courseOrder || 0);

        if (stage) {
          const existingStage = result.find((group) => group.stage === stage);

          if (existingStage) {
            existingStage.courses.push(course);
          } else {
            result.push({
              stage,
              courses: [course],
            } as GroupedCourses);
          }
        }

        return result;
      },
      []
    );

    return groupedCourses;
  };

  const getCourseById = (courseId: string): Course | undefined => {
    return allCoursesData?.courses?.find(
      (course) => course?.id === courseId
    ) as Course;
  };

  const getCourseByCourseOrder = (courseOrder: number): Course | undefined => {
    return allCoursesData?.courses?.find(
      (course) => course?.order === courseOrder
    ) as Course;
  };

  const getYearUpdateCourse = (classData: Classroom) => {
    return !classData.checked ||
      (classData.provider && !classData.providerChecked)
      ? getCourseByCourseOrder(classData.courseOrder! - 1)?.name!
      : undefined;
  };

  const isSuccess = isCoursesSuccess && isOrganizationCoursesSuccess;

  return {
    allCourses,
    courses,
    coursesForSelectCoursePage,
    isLoading,
    isSuccess,
    getCourseByCourseOrder,
    getCourseById,
    getYearUpdateCourse,
    groupCoursesByStage,
    toOptions,
    validCoursesByCourse,
  };
}
