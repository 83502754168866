import styled from '@emotion/styled';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import { SemanticType, useSemantic } from '../../hooks/useSemantic';

import { Icon } from '../icon';
import { Typography } from '../typography';

type Props = {
  type: Exclude<SemanticType, 'info'>;
  text: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export function SemanticMessage({ type, text }: Props): EmotionJSX.Element {
  const { getIcon, getColor } = useSemantic();

  return (
    <Container role="status">
      <Icon bgColor={getColor(type)} icon={getIcon(type)} size="S" />
      <Typography.SuperTitle role="heading" color={getColor(type)}>
        {text}
      </Typography.SuperTitle>
    </Container>
  );
}
