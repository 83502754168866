import styled from '@emotion/styled';
import { Tooltip } from '../tooltip';
import { getElevation } from '../elevation';
import { css } from '@emotion/react';

export type BarProps = {
  values: number[];
  type?: 'percentage' | 'values';
  width?: number | string;
  height?: number | string;
  colors?: string[];
  backgroundColor?: string;
  spaceBetweenColor?: string;
  hasGap?: boolean;
  tooltips?: string[];
};

const BarWrapper = styled.div<{
  width: number | string;
  height: number | string;
  backgroundColor?: string;
  hasGap?: boolean;
  printedBarSegments?: (number | undefined)[];
  hasTooltips?: boolean;
}>`
  ${({
    width,
    height,
    backgroundColor = 'transparent',
    hasGap,
    printedBarSegments,
    hasTooltips,
  }) => css`
    width: ${typeof width === 'number' ? `${width}px` : width};
    height: ${typeof height === 'number' ? `${height}px` : height};
    background-color: ${backgroundColor};
    display: flex;
    flex-direction: row;
    gap: ${hasGap ? '2px' : '0'};

    ${printedBarSegments?.length === 1 &&
    css`
      .barSegment-${printedBarSegments[0]} {
        border-radius: 6px;
      }
    `}

    ${printedBarSegments!.length > 1 &&
    css`
      .barSegment-${printedBarSegments![0]} {
        border-radius: 6px 0 0 6px;
      }

      .barSegment-${printedBarSegments![printedBarSegments!.length - 1]} {
        border-radius: 0 6px 6px 0;
      }
    `}

    ${hasTooltips &&
    css`
      :hover .barSegment {
        opacity: 0.3;
      }
    `}
  `}
`;

const BarSegmentWrapper = styled.div<{ width: number }>`
  position: relative;
  width: ${({ width }) => `${width}%`};
  min-width: ${({ width }) => (width > 0 ? '8px' : undefined)};
  height: 100%;
  pointer-events: none;

  span {
    width: 100%;
  }
`;

const BarSegment = styled.div<{
  color?: string;
  index: number;
  hasTooltip?: boolean;
}>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme, color }) =>
    color
      ? color
      : theme.tokens.color.specific.reports['progress-bar'].empty.value};
  transition: opacity 0.2s ease-in-out;
`;

const BarSegmentHovered = styled.div<{
  color?: string;
  hasTooltip?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: calc(100% + 6px);
  height: calc(100% + 4px) !important;
  background-color: ${({ color }) => color};
  border-radius: 6px;
  border: 2px solid white;
  pointer-events: all;

  ${({ theme }) => getElevation(theme, 'elevation 2')};
  opacity: 0;

  :hover {
    opacity: 1;
  }

  transition: opacity 0.2s ease-in-out;
`;

export function Bar({
  values,
  type = 'percentage',
  width = 64,
  height = 4,
  colors,
  backgroundColor,
  spaceBetweenColor,
  hasGap = true,
  tooltips,
}: BarProps): JSX.Element | null {
  const hasTooltips = tooltips && tooltips.length > 0;
  const total = values.reduce((a, b) => a + b, 0);
  if (total <= 0) return null;
  const newValues =
    type === 'percentage' && total <= 100
      ? total === 100
        ? values
        : values.concat(100 - total)
      : values.map((value: number) => (100 * value) / total);
  const printedBarSegments = newValues
    .map((value, index) => {
      if (value > 0) return index;
      else return undefined;
    })
    .filter((value) => value !== undefined);

  const getColorBar = (index: number): string | undefined => {
    if (!colors || colors.length <= index) {
      return backgroundColor;
    } else return colors[index];
  };

  return (
    <BarWrapper
      backgroundColor={spaceBetweenColor}
      hasGap={hasGap}
      hasTooltips={hasTooltips}
      height={height}
      printedBarSegments={printedBarSegments}
      role="progressbar"
      width={width}
    >
      {newValues.map(
        (value: number, index: number) =>
          value > 0 && (
            <BarSegmentWrapper
              width={value}
              key={`barSegmentWrapper-${index}`}
              data-testid="bar-segment-wrapper"
            >
              <BarSegment
                data-testid="bar-segment"
                className={`barSegment barSegment-${index}`}
                index={index}
                color={getColorBar(index)}
              />
              {hasTooltips && !!tooltips[index] && (
                <Tooltip
                  popperOptions={{ strategy: 'fixed' }}
                  content={tooltips ? tooltips[index] : undefined}
                  offset={[0, -20]}
                >
                  <BarSegmentHovered
                    data-testid="bar-segment-hovered"
                    className={`barSegmentHovered barSegmentHovered-${index}`}
                    color={getColorBar(index)}
                  />
                </Tooltip>
              )}
            </BarSegmentWrapper>
          )
      )}
    </BarWrapper>
  );
}
