import type { Region } from './_generated/locales';
import { LANGUAGES } from './_generated/locales';

export const PREPR_LANGUAGES_MAPPER = {
  ca: 'ca-ES',
  en: 'en-GB',
  en_US: 'en-US',
  es_CL: 'es-CL',
  es_CO: 'es-CO',
  es_EC: 'es-EC',
  es: 'es-ES',
  es_MX: 'es-MX',
  eu: 'eu-ES',
  it_IT: 'it-IT',
  pt_BR: 'pt-BR',
  va: 'va-ES',
  es_419: 'es-LA',
};

type PreprKeys = keyof typeof PREPR_LANGUAGES_MAPPER;
type PreprValues = (typeof PREPR_LANGUAGES_MAPPER)[PreprKeys];

export function getLocalesForPrepr(
  region: Region | undefined,
  locale: string
): PreprValues[] {
  if (!region) {
    return [];
  }

  const locales = LANGUAGES.region[region as Region].locale;

  const { fallback } =
    (locales[locale as keyof typeof locales] as {
      fallback: string[];
    }) || Object.values(locales)[0]; // To avoid blank page when locale is not found

  const mappedFallback: PreprValues[] = fallback.map(
    (f) => PREPR_LANGUAGES_MAPPER[f as PreprKeys]
  );

  const currentLanguage = PREPR_LANGUAGES_MAPPER[locale as PreprKeys];

  const fallbacks = [currentLanguage, ...mappedFallback];

  return fallbacks;
}
