import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '../typography';
import { SemanticType } from '../../hooks/useSemantic';
import { ImageComponent } from '../image-component';

export const AvatarWrapper = styled.div<{
  semantic?: SemanticType;
  bgColor: string;
  size: number;
  hasText: boolean;
  withBorder?: boolean;
  isDisabled?: boolean;
}>`
  position: relative;

  ${({ size }) => {
    return css`
      width: ${size}px;
      height: ${size}px;
      min-width: ${size}px;
      min-height: ${size}px;
    `;
  }};

  background-color: ${({ theme }) =>
    theme.tokens.color.global.neutral.neutral200.value};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ onClick, theme }) =>
    onClick &&
    css`
      :hover {
        cursor: pointer;
        .avatar-state-layer {
          background-color: ${theme.tokens.color.specific['state-layer'][
            'state-hover-darker'
          ].value};
          opacity: 0.5;
          border-radius: 50%;
          z-index: auto;
        }
      }
    `}

  ${({ hasText, theme }) =>
    hasText &&
    css`
      background-color: ${theme.tokens.color.global.teal.teal400.value};
      color: ${theme.tokens.color.global.white.value};
    `}

    ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      .avatar-state-layer {
        background-color: ${theme.tokens.color.alias.cm.bg['bg-accent'].value};
        opacity: 0.5;
        border-radius: 50%;
        z-index: auto;
      }
      pointer-events: none;
    `}
`;

export const Image = styled(ImageComponent)<{
  semantic?: SemanticType;
  size: number;
}>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  display: block;
`;

export const Text = styled(Typography.Subtitle1)``;

export const AvatarBadge = styled.div<{
  semantic?: SemanticType;
  bgColor?: string;
}>`
  background-color: ${({ theme }) =>
    theme.tokens.color.global.neutral.neutral100.value};
  top: -4px;
  right: -4px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  height: fit-content;
  width: fit-content;
  z-index: 11;

  ${({ semantic, theme, bgColor }) =>
    semantic &&
    css`
      background-color: ${bgColor};
      svg path {
        fill: ${theme.tokens.color.global.white.value};
      }
    `}

  ${({ bgColor }) =>
    css`
      background-color: ${bgColor};
    `}
`;

export const SemanticBorder = styled.div<{
  bgColor?: string;
}>`
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 10;
  box-sizing: border-box;
  background-color: transparent;
  pointer-events: none;
  box-shadow: inset 0 0 0 2px ${({ bgColor }) => bgColor},
    inset 0 0 0 2px ${({ bgColor }) => bgColor};
  border-radius: 50%;
`;
