import { getAcceptLanguage } from '@innovamat/localization';
import { useQuery } from '@tanstack/react-query';
import type { AppletsBySessionCodeQuery } from '../../generated/types';
import { AppletsBySessionCodeDocument } from '../../generated/types';
import { useGlowApiClient } from '../../graphql-provider';

type Props = {
  language: string;
  region: string;
  classroomId: string;
  courseId: string;
  courseOrder: number;
  sessionCode: string;
};

function useUnblockedApplets({
  language,
  region,
  classroomId,
  courseId,
  courseOrder,
  sessionCode,
}: Props) {
  const { graphqlClient, getAuthToken } = useGlowApiClient();

  const variables = {
    classroomId: classroomId,
    locale: language,
    courseId: courseId,
    courseOrder: courseOrder,
    sessionCode: sessionCode,
  };

  const appletsQuery = useQuery<AppletsBySessionCodeQuery>({
    queryKey: ['Resource', variables, region, language],
    queryFn: () => {
      const headers = {
        'accept-language': getAcceptLanguage(region, language) ?? '*',
        authorization: getAuthToken() ?? '',
      };
      return graphqlClient.request(
        AppletsBySessionCodeDocument,
        variables,
        headers
      );
    },
    enabled: !!sessionCode && !!classroomId && !!region && !!language,

    retry: false,
  });

  return appletsQuery;
}

export { useUnblockedApplets };
