import styled from '@emotion/styled';
import { Tooltip } from '@innovamat/glimmer-components';
import { StandardContent } from '@innovamat/resource-viewer';
import { useTranslation } from 'react-i18next';
import { Tag } from '../../components/Tag';
import { getStandardTags } from '../../utils/standards';
import Block from './Block';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

type Props = {
  tags?: string[];
  standardContent: StandardContent[];
};

export function Standards({ tags, standardContent }: Props) {
  const { t } = useTranslation();
  const standardTags = getStandardTags(standardContent);

  const renderStandardTags = () => (
    <Container>
      {standardTags.map((tag, index) => (
        <Tooltip key={`${tag.text}-${index}`} content={t(tag.tooltipKey)}>
          <Tag text={tag.text} isEnabled={true} />
        </Tooltip>
      ))}
    </Container>
  );

  const renderTags = () => (
    <Container>
      {tags?.map((tag, index) => (
        <Tag key={`${tag}-${index}`} text={tag} isEnabled={true} />
      ))}
    </Container>
  );

  if (standardTags?.length > 0) {
    return (
      <Block title={`${t('digitalguides.standards')}`}>
        {renderStandardTags()}
      </Block>
    );
  }

  if (!tags || tags.length === 0) {
    return null;
  }

  return <Block title={t('digitalguides.standards')}>{renderTags()}</Block>;
}
