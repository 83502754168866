import type { StandardContent } from '@innovamat/resource-viewer';
import type { ContentBlockTag, StandardTag } from '../types/SmartGuide';

const isBlock = (value: string) => {
  const regex = /^block_/;
  return regex.test(value);
};

const getStandardBlocks = (
  standardContent: StandardContent[]
): ContentBlockTag[] => {
  return standardContent
    ?.filter((standard) => isBlock(standard.blockKey))
    .map((standard) => ({
      textKey: standard.blockKey,
      isEnabled: true,
    }));
};

const hasContentBlocks = (standardContent: StandardContent[]): boolean => {
  return standardContent?.every((standard) => isBlock(standard.blockKey));
};

const getStandardTags = (standardContent: StandardContent[]): StandardTag[] => {
  return standardContent
    ?.filter((standard) => !isBlock(standard.blockKey))
    .flatMap((standard) => standard.content)
    .map((content) => ({
      text: content.contentValue,
      tooltipKey: content.contentKey,
      isSupporting: !content.mainStandard,
    }))
    .sort((a, b) => Number(a.isSupporting) - Number(b.isSupporting));
};

export { getStandardBlocks, getStandardTags, hasContentBlocks };
