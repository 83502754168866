import styled from '@emotion/styled';
import {
  Button,
  Modal,
  Rating,
  Typography,
} from '@innovamat/glimmer-components';
import { TFunction } from 'i18next';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  AfterReviewContent,
  BeforeReviewContent,
  Divider,
  Footer,
  Optional,
  QuestionTextWrapper,
  QuestionWrapper,
  TextArea,
} from './session-done-modal.styles';

type UnlockModalProps = {
  t: TFunction<'translation', undefined, 'translation'>;
  isOpen: boolean;
  onClose: () => void;
  handleSendReview: () => void;
  applets: ReactNode;
  solutions: ReactNode;
  handleRating: (value: number | null) => void;
  ratingValue: number | null;
  handleReview: (value: string) => void;
  handleErrorReview: (value: string) => void;
  hasAlreadyValue: boolean;
  isOnlyRating: boolean;
  isSendReviewPending: boolean;
  handleResetRating: () => void;
  review: string;
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GapContent = styled(Content)`
  gap: 16px;
`;

const ReviewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export function UnlockModal({
  t,
  isOpen,
  onClose,
  handleSendReview,
  applets,
  solutions,
  handleRating,
  ratingValue,
  handleReview,
  hasAlreadyValue,
  handleErrorReview,
  isOnlyRating,
  isSendReviewPending,
  handleResetRating,
  review,
}: UnlockModalProps): JSX.Element {
  const beforeRef = useRef<HTMLDivElement>(null);
  const afterRef = useRef<HTMLDivElement>(null);
  const [beforeHeight, setBeforeHeight] = useState<number | undefined>();
  const [afterHeight, setAfterHeight] = useState<number | undefined>();
  const [showErrorInput, setShowErrorInput] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const getShowReviewZone = () => {
    if (isModified) return true;
    if (isOnlyRating) return true;
    if (hasAlreadyValue) return false;
    return false;
  };

  const getShowAppletsZone = () => {
    if (isOnlyRating) return false;
    if (isModified) return false;
    return true;
  };

  const showReviewContent = getShowReviewZone();
  const showAppletsContent = getShowAppletsZone();

  const handleGoBack = () => {
    setIsModified(false);
    handleResetRating();
  };

  useEffect(() => {
    if (!isOpen) {
      setShowErrorInput(false);
      setIsModified(false);
      return;
    }

    if (afterRef.current) {
      setAfterHeight(afterRef.current.scrollHeight);
    }

    if (beforeRef.current) {
      setBeforeHeight(beforeRef.current.scrollHeight);
    }
  }, [isOpen, showErrorInput]);

  return (
    <Modal
      closeOnEsc
      isOpen={isOpen}
      modalWidth="768px"
      onClose={onClose}
      overlayTestId="blockLayoutModal"
      title={
        isOnlyRating
          ? t('session.sesion-review.modal.title')
          : t('session.unlockModal.unlockContent.title')
      }
      closeButton="inner"
    >
      <Content>
        {!isOnlyRating && (
          <BeforeReviewContent
            ref={beforeRef}
            height={beforeHeight!}
            isVisible={showAppletsContent}
          >
            {solutions}
            {applets}
          </BeforeReviewContent>
        )}

        <ReviewWrapper>
          <Typography.Subtitle1>
            {t('session.sesion-review.modal.subtitle')}
          </Typography.Subtitle1>
          <Rating
            onChange={(e, value) => {
              setIsModified(true);
              handleRating(value);
            }}
            value={ratingValue}
          />
        </ReviewWrapper>
        <AfterReviewContent
          ref={afterRef}
          isVisible={showReviewContent}
          height={afterHeight!}
        >
          <GapContent>
            <Divider margin="top" />

            <QuestionWrapper>
              <QuestionTextWrapper>
                <Typography.Subtitle1>
                  <Trans
                    i18nKey="session.sesion-review.modal.error-title"
                    components={{
                      small: <Optional />,
                    }}
                  />
                </Typography.Subtitle1>
                <Optional>
                  {t('session.sesion-review.modal.error-description')}
                </Optional>
              </QuestionTextWrapper>
              {showErrorInput ? (
                <TextArea
                  placeholder={t(
                    'session.sesion-review.modal.report-error.plahceholder'
                  )}
                  rows={2}
                  onChange={(e) => handleErrorReview(e.target.value)}
                ></TextArea>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => setShowErrorInput(true)}
                >
                  {t('session.sesion-review.modal.report-error.button')}
                </Button>
              )}
            </QuestionWrapper>

            <Typography.Subtitle1>
              <Trans
                i18nKey="session.sesion-review.modal.message.content"
                components={{
                  small: <Optional />,
                }}
              />
            </Typography.Subtitle1>
            <TextArea
              placeholder={t('session.sesion-review.modal.message-placeholder')}
              rows={2}
              onChange={(e) => handleReview(e.target.value)}
              value={review}
            ></TextArea>
            <Footer>
              {!isOnlyRating && (
                <Button onClick={handleGoBack} variant="tertiary">
                  {t('back.button')}
                </Button>
              )}

              <Button
                loading={isSendReviewPending}
                onClick={handleSendReview}
                disabled={ratingValue === null}
              >
                {t('session.sesion-review.modal.send')}
              </Button>
            </Footer>
          </GapContent>
        </AfterReviewContent>
      </Content>
    </Modal>
  );
}
