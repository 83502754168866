import { Suspense, lazy } from 'react';
import styled from '@emotion/styled';
import type { IconSize } from '../icon';
import type { IconType } from '@innovamat/glimmer-icons';
import { StateLayer } from '../../utils/common.styled';

enum Sizes {
  XS = 16,
  S = 24,
  M = 32,
  L = 40,
  XL = 64,
}

export type IconContainerSize = keyof typeof Sizes;

type Props = {
  icon: IconType;
  size?: IconContainerSize;
  iconSize?: IconSize;
  className?: string;
  iconColor?: string;
  bgColor?: string;
  isRounded?: boolean;
  isHoverEnabled?: boolean;
};

const IconContainer = styled.div<{ size: string; isRounded?: boolean }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  min-width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  display: grid;
  place-items: center;
  border: none;
  border-radius: ${({ isRounded }) => (isRounded ? '50%' : '4px')};
  position: relative;

  :hover .icon-stateLayer {
    border-radius: ${({ isRounded }) => (isRounded ? '50%' : '4px')};
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-hover-darker'].value};
  }

  :active .icon-stateLayer {
    border-radius: ${({ isRounded }) => (isRounded ? '50%' : '4px')};
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-press-darker'].value};
  }
`;

const sizeMap = {
  XS: 'S',
  S: 'M',
  M: 'L',
  L: 'L',
  XL: 'XL',
};

// Lazily load the Icon component
const Icon = lazy(() =>
  import('../icon').then((module) => ({ default: module.Icon }))
);

export function IconBase({
  icon,
  size = 'L',
  className,
  iconSize,
  iconColor,
  bgColor,
  isRounded = false,
  isHoverEnabled = true,
}: Props): JSX.Element {
  const iconContainerSize = `${Sizes[size]}px`;

  const getMappedSize = () => {
    if (iconSize) return iconSize;
    if (!iconSize) return sizeMap[size] as IconContainerSize;
    return undefined;
  };

  return (
    <IconContainer
      className={className}
      size={iconContainerSize}
      isRounded={isRounded}
    >
      {isHoverEnabled && <StateLayer className="icon-stateLayer" />}
      <Suspense fallback={<></>}>
        <Icon
          icon={icon}
          iconColor={iconColor}
          bgColor={bgColor}
          size={getMappedSize()}
        />
      </Suspense>
    </IconContainer>
  );
}
